import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";
import {useEditPageTools} from "../hooks/useEditPageTools";
import {usePolicyTemplate} from "../hooks/usePolicyTemplate";
import {useDocumentTitle} from "@mantine/hooks";

export const EditPage = () => {

    const {
        id: policyStoreId,
        policyTemplateId
    } = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const {
        record: policyTemplate,
        handleFetchRecord,
        handleUpdateRecord
    } = usePolicyTemplate(policyStoreId, policyTemplateId);

    useDocumentTitle(`Policy Template: ${policyTemplate.id}`);

    const {
        mode,
        tools
    } = useEditPageTools();


    useEffect(() => {
        handleFetchRecord();
    }, []);


    if (typeof policyTemplate === 'undefined' || !policyTemplate.statement) {
        return <PlatformLoader message='Loading policy template...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Policy Template: ${policyTemplate.id}`)}
            pageNavigation={<EditPageNavigation record={policyTemplate}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={policyTemplate}
                    />
                </Stack>
                :
                <SimplePageContentWrapper>
                    <RecordForm
                        record={policyTemplate}
                        handleSubmit={handleUpdateRecord}
                    />
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
