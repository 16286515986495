import {I18n} from '@aws-amplify/core';

import {SimplePageHeader} from '@pac/platform-ui-components';
import {PolicyStoreIcon} from '../../policy-stores/images/PolicyStoreIcon.tsx';


const useTools = () => {
    const tools = [] as any[];
    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Policy Stores')}
            icon={<PolicyStoreIcon/>}
            tools={useTools()}
        />
    );
};
