import {
    setPage,
    setPageSize,
} from '../reducers/index';

import {visibilitySelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";

export const useActionsPaging = (policyStoreId: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        limit,
        page,
        totalPages,
        visibleCount
    } = useAppSelector((state) => visibilitySelector(state, policyStoreId));


    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };


    return {
        limit,
        page,
        totalPages,
        visibleCount,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
