import {createSelector} from "@reduxjs/toolkit";
import {moduleConfig} from "./config";
import {visibilityFilter} from "../../utils/VisibilityFilter.ts";
import {RootState} from "../../store";

const key = moduleConfig.entityKey;


// @see this - https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization


export const recordsSelector = (state: RootState) => state[key].records;


export const ORIGrecordSelector = (state: RootState, id: string) => {
    const records = recordsSelector(state);
    const loading = records.loading;
    const record = records.byId[id];
    return {
        record,
        loading
    }
};


export const recordSelector = createSelector([recordsSelector, (_, id) => id], (records, id) => {
    const loading = records.loading;
    const record = records.byId[id];
    return {
        record,
        loading
    }
})

export const selectionSelector = (state: RootState) => {
    return state[key].selection;
};


export const tenantsSelector = (state: RootState) => {
    const collection = state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });

    return collection;
};


export const visibilitySelector = (state: RootState) => {
    const items = tenantsSelector(state);

    const page = state[key].pagination.page;
    const limit = state[key].pagination.limit;

    const keyword = state[key].visibility.keyword;
    const visible = state[key].visibility.visible;
    const columns = state[key].visibility.columns;

    const visibleItems = visibilityFilter(items, page, limit, keyword);

    const totalCount = state[key].records.totalCount;

    let visibleCount = totalCount;
    if (keyword) {
        visibleCount = visibleItems.length;
    }

    const totalPages = Math.ceil(visibleCount / limit);

    return {
        items: items,
        visibleItems: visibleItems,
        page: page,
        limit: limit,
        keyword: keyword,
        visible: visible,
        columns: columns,
        visibleCount: visibleCount,
        totalPages: totalPages,
    };
};


export const tenantSelector = (state: RootState, id: string) => {
    return state[key].records.byId[id];
};

export const tenantsCountSelector = (state: RootState) => {
    return state[key].records.totalCount;
};

