export const moduleConfig = {
    entityKey: 'policyTemplates'
}

export const policyTemplateNamespace = 'Console'


export const initialSortState = {
    column: 'created',
    direction: 'desc',
};