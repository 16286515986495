import {
    clearState,
    deletePolicy,
    fetchPolicies
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {useSearchParams} from "react-router-dom";

export const usePolicies = (policyStoreId: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const selection = useAppSelector((state) => selectionSelector(state));

    const [searchParams] = useSearchParams()

    const {
        items,
        visibleItems,
        keyword,
    } = useAppSelector((state) => visibilitySelector(state, policyStoreId, searchParams));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );

    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchPolicies({
                                   policyStoreId
                               }))
    };

    const handleReloadRecords = (query?: object) => {
        if (query) {
            console.debug('query', query)
        }

        dispatch(clearState());
        dispatch(fetchPolicies({
                                   policyStoreId,
                               }))
    };

    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deletePolicy({
                                          policyStoreId: policyStoreId,
                                          id: key
                                      }));
            }
        }
    };


    return {
        items,
        visibleItems,
        loading,
        selection,
        keyword,
        handleFetchRecords,
        handleReloadRecords,
        handleDeleteRecords,
    };
};
