import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../identity-sources/services/IdentitySourcesService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {IdentitySource,} from "../types.ts";
import {moduleConfig} from "../config";
import {recordSelector} from "../../policy-stores";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (permissionSetId: string, record: IdentitySource) => {

    return Object.assign({}, record, {
        id: record.identitySourceId,
        permissionSetId: permissionSetId,
    });
};

// genenrates pending, fulfilled and rejected
export const fetchIdentitySources = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    ({
         permissionSetId
     }: { permissionSetId: string }, {getState}) => {

        const state = getState();
        const {record: permissionSet} = recordSelector(state, permissionSetId)
        const deploymentEnvironmentId = permissionSet.deploymentEnvironmentId
        const policyStoreId = permissionSet.policyStoreId

        return fetchRecords(deploymentEnvironmentId, policyStoreId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(permissionSetId, record);
                });
            });
    },
);

export const fetchIdentitySource = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         permissionSetId,
         id
     }: {
        permissionSetId: string,
        id: string
    }, {getState}) => {

        const state = getState();
        const {record: permissionSet} = recordSelector(state, permissionSetId)
        const deploymentEnvironmentId = permissionSet.deploymentEnvironmentId
        const policyStoreId = permissionSet.policyStoreId

        return fetch(deploymentEnvironmentId, policyStoreId, id)
            .then((response) => {
                return normalizeRecord(permissionSetId, response as IdentitySource);
            });
    });


export const saveIdentitySource = createAsyncThunk(
    `${entityKey}/add`,
    ({
         permissionSetId,
         payload
     }: {
        permissionSetId: string,
        payload: IdentitySource
    }, {getState}) => {

        const state = getState();
        const {record: permissionSet} = recordSelector(state, permissionSetId)
        const deploymentEnvironmentId = permissionSet.deploymentEnvironmentId
        const policyStoreId = permissionSet.policyStoreId

        return add(deploymentEnvironmentId, policyStoreId, payload)
            .then((response) => normalizeRecord(permissionSetId, response as IdentitySource));
    });

export const updateIdentitySource = createAsyncThunk(
    `${entityKey}/update`,
    ({
         permissionSetId,
         id,
         record
     }: {
        permissionSetId: string,
        id: string,
        record: IdentitySource
    }, {getState}) => {


        const state = getState();
        const {record: permissionSet} = recordSelector(state, permissionSetId)
        const deploymentEnvironmentId = permissionSet.deploymentEnvironmentId
        const policyStoreId = permissionSet.policyStoreId

        return update(deploymentEnvironmentId, policyStoreId, id, record)
            .then((response) => {
                return normalizeRecord(permissionSetId, response as IdentitySource);
            });
    },
);

export const deleteIdentitySource = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         permissionSetId,
         id
     }: {
        permissionSetId: string,
        id: string
    }, {getState}) => {

        const state = getState();
        const {record: permissionSet} = recordSelector(state, permissionSetId)
        const deploymentEnvironmentId = permissionSet.deploymentEnvironmentId
        const policyStoreId = permissionSet.policyStoreId

        return deleteRecord(deploymentEnvironmentId, policyStoreId, id)
            .then(() => id);
    },
);

const identitySourcesSlice = createSlice({
                                             name: entityKey,
                                             initialState,
                                             reducers: {
                                                 clearState: () => initialState,
                                             },
                                             extraReducers: (builder) => {
                                                 // FETCH MANY
                                                 fetchManyCase(builder, fetchIdentitySources, entityKey);

                                                 // FETCH ONE
                                                 fetchOneCase(builder, fetchIdentitySource);

                                                 // SAVE ONE
                                                 saveOneCase(builder, saveIdentitySource);

                                                 // UPDATE ONE
                                                 updateOneCase(builder, updateIdentitySource);

                                                 // DELETE ONE
                                                 deleteOneCase(builder, deleteIdentitySource);
                                             },
                                         });

export default identitySourcesSlice.reducer;
export const {clearState} = identitySourcesSlice.actions;
