import {PermissionGroup} from "../types.ts";
import {usePolicyStoreBreadcrumbElements} from "../../policy-stores/hooks/usePolicyStoreBreadcrumbElements.tsx";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";


export const EditPageNavigation = ({record}: { record: PermissionGroup }) => {

    const {elements: rootPathElements} = usePolicyStoreBreadcrumbElements(record.policyStoreId)

    const elements = rootPathElements.concat([
                                                 {
                                                     title: 'Permission Group',
                                                 },
                                                 {title: record.name}
                                             ]);
    return renderBreadcrumbs(elements)
};
