import {useEffect} from 'react';

import {MultiSelect} from '@mantine/core';
import {useEntityTypes} from "../hooks/useEntityTypes";
import {FieldProps} from "formik";
import {useParams} from "react-router-dom";
import {NEXTEntityType} from "../types.ts";

export const EntityTypeNamesMultiSelect = ({
                                               field: {
                                                   name,
                                                   value
                                               },
                                               form: {setFieldValue},
                                               ...rest
                                           }: FieldProps) => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        items,
        handleFetchRecords
    } = useEntityTypes(policyStoreId)

    useEffect(() => {
        handleFetchRecords();
    }, []);

    const data = items.map((item: NEXTEntityType) => {
        return {
            value: item.name,
            label: item.name
        };
    });


    const handleChange = (value: string[]) => {
        setFieldValue(name, value);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            searchable
            {...rest}
        />
    );
};
