import {I18n} from '@aws-amplify/core';

import {NextDeleteButton} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom';

import {useEntityTypes} from '../hooks/useEntityTypes';

import {openDeleteConfirmationModal} from "../../../components/DeleteConfirmationHandler.tsx";
import {useEntityTypesSelection} from "../hooks/useEntityTypesSelection.ts";


export const DeleteRecordButton = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        handleDeleteRecords
    } = useEntityTypes(policyStoreId);

    const {
        selection,
        handleClearSelection
    } = useEntityTypesSelection(policyStoreId);


    const recordIds = Object.keys(selection);

    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false
    }


    return (
        <NextDeleteButton
            disabled={disabled}
            onClick={() => openDeleteConfirmationModal(() => {
                handleDeleteRecords();
                handleClearSelection();
            })}
        >
            {recordIds.length > 0 ? [I18n.get('Delete'), recordIds.length, 'Selected'].join(' ') : I18n.get('Delete')}
        </NextDeleteButton>
    );
};

