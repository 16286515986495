import {
    NavLink as ReactRouteNavLink,
    useParams
} from 'react-router-dom';
import {usePolicies} from "../../../policies";
import {useEffect} from "react";
import {
    Group,
    Text
} from "@mantine/core";
import {PolicyIcon} from "../../../policies/images/PolicyIcon.tsx";
import {DashboardWidgetBuilder} from "@pac/platform-ui-components";
import {useVerifiedPermissions} from "../../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../../config/rbac/actions.ts";
import {usePoliciesPaging} from "../../../policies/hooks/usePoliciesPaging.ts";


export const PoliciesWidget = () => {
    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        handleFetchRecords: fetchPolicies
    } = usePolicies(policyStoreId)

    const {
        totalCount: visibleCount,
    } = usePoliciesPaging(policyStoreId)

    const {allowedActions} = useVerifiedPermissions()

    useEffect(() => {
        if (allowedActions.includes(rbacActions.ListPolicies)) {
            fetchPolicies();
        }

    }, []);

    const widget = {
        header: {
            content: <Group p={'sm'}><PolicyIcon/><Text title={'Policies'}>Policies</Text></Group>,
        },
        body: {
            dataPoints: [
                {
                    value: visibleCount,
                    label: 'Policies',
                    color: '#194a74',
                },
            ],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'All Policies',
                    component: {ReactRouteNavLink},
                    to: `/policy-stores/${policyStoreId}/#policies`,
                    visible: true,
                },
                {
                    label: 'Add Policy',
                    component: {ReactRouteNavLink},
                    to: `/policy-stores/${policyStoreId}/policies/add`,
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
