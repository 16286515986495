import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/AddPageNavigation.tsx";
import {useEntityType} from "../hooks/useEntityType";
import {useParams} from "react-router-dom";
import {useDocumentTitle} from "@mantine/hooks";

export const AddPage: FunctionComponent = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        handleCreateRecord
    } = useEntityType(policyStoreId);

    useDocumentTitle('Add Entity Type');

    return (
        <FormPageLayout
            title='Add Entity Type'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={handleCreateRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
