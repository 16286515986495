import {
    renderBreadcrumbs,
    rootPathElements
} from "./CommonPageNavigation.tsx";

export const AddPageNavigation = () => {

    const elements = rootPathElements()
        .concat([
                    {title: 'Add Data Source'}
                ]);
    return renderBreadcrumbs(elements)
};
