import {
    addToSelection,
    clearSelection,
    removeFromSelection
} from '../reducers/index';

import {
    selectionSelector,
    visibilitySelector
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {PolicyTemplate} from "../types.ts";


export const usePolicyTemplatesSelection = (policyStoreId: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const selection = useAppSelector((state) => selectionSelector(state));

    const {items} =
        useAppSelector((state) => visibilitySelector(state, policyStoreId));


    // SELECTION
    const handleRowSelection = (row: PolicyTemplate) => {
        if (typeof selection[row.id] !== 'undefined') {
            dispatch(removeFromSelection(row.id));
        } else {
            dispatch(addToSelection(row));
        }
    };

    const handleSelectAllRows = () => {
        for (let i = 0; i < items.length; i++) {
            if (typeof selection[items[i].id] !== 'undefined') {
                dispatch(removeFromSelection(items[i].id));
            } else {
                dispatch(addToSelection(items[i]));
            }
        }
    };

    const handleClearSelection = () => {
        dispatch(clearSelection());
    };

    return {
        items,
        selection,
        handleRowSelection,
        handleSelectAllRows,
        handleClearSelection,
    };
};
