export const createIdentitySource = `
  mutation createIdentitySource($input: CreateIdentitySourceInput!) {
    createIdentitySource(input: $input){
      identitySourceId
      policyStoreId
      userPoolArn
      clientIds
      groupEntityType
      principalEntityType
    }
  }  
`;

export const updateIdentitySource = `
  mutation updateIdentitySource($input: UpdateIdentitySourceInput!) {
    updateIdentitySource(input: $input){
      identitySourceId
      policyStoreId
      userPoolArn
      clientIds
      groupEntityType
      principalEntityType
    }
  }
`;

export const deleteIdentitySource = `
  mutation deleteIdentitySource($input: DeleteIdentitySourceInput!) {
    deleteIdentitySource(input: $input){
      identitySourceId
    }
  }  
`;
