import {NextReloadButton} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom';

import {usePolicyTemplates} from '../hooks/usePolicyTemplates';

export const ReloadRecordsButton = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {handleFetchRecords} = usePolicyTemplates(policyStoreId);

    return (<NextReloadButton onClick={handleFetchRecords}/>);
};
