import {useParams} from 'react-router-dom';
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";
import {useBreadcrumbMenuItems} from "../hooks/useBreadcrumbMenuItems.tsx";


export const AddPageNavigation = () => {
    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    let elements = useBreadcrumbMenuItems(policyStoreId)

    elements = elements.concat([
                                   {title: 'Add Policy Template'}
                               ]);
    return renderBreadcrumbs(elements)
};

