export enum rbacActions {
    GetDashboard = 'GetDashboard',

    ListActions = 'ListActions',
    GetAction = 'GetAction',
    CreateAction = 'CreateAction',
    UpdateAction = 'UpdateAction',
    DeleteAction = 'DeleteAction',

    ListAuthorizationRequests = 'ListAuthorizationRequests',
    GetAuthorizationRequest = 'GetAuthorizationRequest',
    CreateAuthorizationRequest = 'CreateAuthorizationRequest',
    UpdateAuthorizationRequest = 'UpdateAuthorizationRequest',
    DeleteAuthorizationRequest = 'DeleteAuthorizationRequest',
    ReplayAuthorizationRequest = 'ReplayAuthorizationRequest',

    ListEntityTypes = 'ListEntityTypes',
    GetEntityType = 'GetEntityType',
    CreateEntityType = 'CreateEntityType',
    UpdateEntityType = 'UpdateEntityType',
    DeleteEntityType = 'DeleteEntityType',

    ListIdentitySources = 'ListIdentitySources',
    GetIdentitySource = 'GetIdentitySource',
    CreateIdentitySource = 'CreateIdentitySource',
    UpdateIdentitySource = 'UpdateIdentitySource',
    DeleteIdentitySource = 'DeleteIdentitySource',

    ListPermissionGroups = 'ListPermissionGroups',
    GetPermissionGroup = 'GetPermissionGroup',
    CreatePermissionGroup = 'CreatePermissionGroup',
    UpdatePermissionGroup = 'UpdatePermissionGroup',
    DeletePermissionGroup = 'DeletePermissionGroup',

    ListPermissionProfiles = 'ListPermissionProfiles',
    GetPermissionProfile = 'GetPermissionProfile',
    CreatePermissionProfile = 'CreatePermissionProfile',
    UpdatePermissionProfile = 'UpdatePermissionProfile',
    DeletePermissionProfile = 'DeletePermissionProfile',

    ListPermissionSets = 'ListPermissionSets',
    GetPermissionSet = 'GetPermissionSet',
    CreatePermissionSet = 'CreatePermissionSet',
    UpdatePermissionSet = 'UpdatePermissionSet',
    DeletePermissionSet = 'DeletePermissionSet',

    ListProfilePermissionSets = 'ListProfilePermissionSets',
    GetProfilePermissionSet = 'GetProfilePermissionSet',
    CreateProfilePermissionSet = 'CreateProfilePermissionSet',
    UpdateProfilePermissionSet = 'UpdateProfilePermissionSet',
    DeleteProfilePermissionSet = 'DeleteProfilePermissionSet',


    ListPolicies = 'ListPolicies',
    GetPolicy = 'GetPolicy',
    CreatePolicy = 'CreatePolicy',
    UpdatePolicy = 'UpdatePolicy',
    DeletePolicy = 'DeletePolicy',

    ListPolicyStores = 'ListPolicyStores',
    GetPolicyStore = 'GetPolicyStore',
    CreatePolicyStore = 'CreatePolicyStore',
    UpdatePolicyStore = 'UpdatePolicyStore',
    DeletePolicyStore = 'DeletePolicyStore',

    ListPolicyTemplates = 'ListPolicyTemplates',
    GetPolicyTemplate = 'GetPolicyTemplate',
    CreatePolicyTemplate = 'CreatePolicyTemplate',
    UpdatePolicyTemplate = 'UpdatePolicyTemplate',
    DeletePolicyTemplate = 'DeletePolicyTemplate',
    TestPolicyTemplate = 'TestPolicyTemplate',

    ListDeploymentEnvironments = 'ListDeploymentEnvironments',
    GetDeploymentEnvironment = 'GetDeploymentEnvironment',
    CreateDeploymentEnvironment = 'CreateDeploymentEnvironment',
    UpdateDeploymentEnvironment = 'UpdateDeploymentEnvironment',
    DeleteDeploymentEnvironment = 'DeleteDeploymentEnvironment',

    ListDataSources = 'ListDataSources',
    GetDataSource = 'GetDataSource',
    CreateDataSource = 'CreateDataSource',
    UpdateDataSource = 'UpdateDataSource',
    DeleteDataSource = 'DeleteDataSource',

    ListIdentityProviders = 'ListIdentityProviders',
    GetIdentityProvider = 'GetIdentityProvider',
    CreateIdentityProvider = 'CreateIdentityProvider',
    UpdateIdentityProvider = 'UpdateIdentityProvider',
    DeleteIdentityProvider = 'DeleteIdentityProvider',

    ListPolicyStoreSchemas = 'ListPolicyStoreSchemas',
    GetPolicyStoreSchema = 'GetPolicyStoreSchema',
    CreatePolicyStoreSchema = 'CreatePolicyStoreSchema',
    UpdatePolicyStoreSchema = 'UpdatePolicyStoreSchema',
    DeletePolicyStoreSchema = 'DeletePolicyStoreSchema',

}

export const actions: rbacActions[] = Object.values(rbacActions);
