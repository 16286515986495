import {
    GetAvpPolicyStore,
    ListAvpPolicyStores
} from "../types.ts";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";
import {notifyError} from "../../../components/ServiceResponseNotifications.tsx";
import {IListRecordsQuery} from "../../../types";
import {
    getAvpPolicyStore,
    listAvpPolicyStores
} from "./queries.ts";

const client = generateClient();

export const fetchRecords = async (query: IListRecordsQuery & { deploymentEnvironmentId: string }) => {

    const {
        deploymentEnvironmentId,
        limit,
        nextToken,
    } = query;

    try {
        const response = await client.graphql<GraphQLQuery<ListAvpPolicyStores>>({
                                                                                     query: listAvpPolicyStores,
                                                                                     variables: {
                                                                                         deploymentEnvironmentId,
                                                                                         limit,
                                                                                         nextToken,
                                                                                     }
                                                                                 })

        return response.data?.listAvpPolicyStores;
    } catch (e) {
        console.debug(e);
    }
};

export const fetch = async (deploymentEnvironmentId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetAvpPolicyStore>>({
                                                                                   query: getAvpPolicyStore,
                                                                                   variables: {
                                                                                       deploymentEnvironmentId,
                                                                                       id
                                                                                   },
                                                                               });
        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getAvpPolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }
    } catch (e: any) {
        notifyError(e);
    }
};

