import {Center} from "@mantine/core";

import {RemoveRowButtonProps} from "./types";
import {NextCloseButtonIcon} from "@pac/platform-ui-components";


export const RemoveRowButton = ({
                                    remove,
                                    rowIndex
                                }: RemoveRowButtonProps) => {

    return (
        <Center>
            <NextCloseButtonIcon
                variant={'outline'}
                size={'lg'}
                onClick={() => {
                    remove(rowIndex);
                }}
            ></NextCloseButtonIcon>
        </Center>
    );
};



