import {
    clearKeyword,
    setKeyword,
    setPage,
    setPageSize,
} from '../reducers/index';

import {
    deleteUser,
    fetchUsers,
    updateUser,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {IListRecordsQuery} from "../../../types";

export const useUsers = () => {
    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        limit,
        keyword,
        page,
        totalPages,
        visibleCount
    } = useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state)
    );

    // FETCH
    const handleFetchRecords = (query: object) => {

        let listQuery = Object.assign({}, query, {
            'response-group': 'small',
            'is-enabled': 'true',
            // limit: limit,
            // page: page,
        }) as IListRecordsQuery;


        if (keyword) {
            listQuery = Object.assign({}, listQuery, {
                search: keyword
            });
        }

        dispatch(fetchUsers(listQuery));
    };

    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(
                    deleteUser({
                                   id: key,
                                   record: selection[key]
                               })
                );
            }
        }
    };

    const handleUpdateNameCase = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {

                const updated = Object.assign({}, selection[key]);

                updated['first-name'] = updated['first-name'].charAt(0)
                                                             .toUpperCase() + updated['first-name'].slice(1);

                updated['last-name'] = updated['last-name'].charAt(0)
                                                           .toUpperCase() + updated['last-name'].slice(1);

                dispatch(
                    updateUser({
                                   id: key,
                                   record: updated
                               })
                );


            }
        }
    };


    // FILTER
    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };


    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    return {
        items,
        visibleItems,
        limit,
        loading,
        keyword,
        page,
        totalPages,
        visibleCount,
        handleFetchRecords,
        handleDeleteRecords,
        handleUpdateNameCase,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
