export const listEntityTypes = `
    query listEntityTypes($policyStoreId:ID!) {
      listEntityTypes(policyStoreId:$policyStoreId) {
        items {
          name
          policyStoreId
          policyStore {
            id
            name
          }
          memberOfTypes
        }
      }
    }
`

export const getEntityType = `
  query getEntityType($id: ID!) {
    getEntityType(id: $id){
      name
      policyStoreId
      policyStore {
        id
        name
      }
      memberOfTypes
    }
  }  
`;