import {useEffect} from 'react';

import {PacFormikDropdown} from '@pac/platform-ui-components';


import {policyStoresSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {FieldProps} from "formik";
import {AvpPolicyStore} from "../types.ts";
import {SelectProps} from "@mantine/core";
import {fetchAvpPolicyStores} from "../reducers/recordsSlice.ts";

export const AvpPolicyStoresDropdown = (props: FieldProps & SelectProps) => {
    const dispatch = useAppDispatch();

    const policyStores = useAppSelector((state) => policyStoresSelector(state));

    useEffect(() => {
        dispatch(fetchAvpPolicyStores(props.form.values.deploymentEnvironmentId));
    }, []);

    const selectOptions = policyStores.map((record: AvpPolicyStore) => {
        return {
            value: record.policyStoreId,
            label: record.policyStoreId + ' - ' + record.description
        };
    });

    return <PacFormikDropdown {...props} data={selectOptions}/>;
};
