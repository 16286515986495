import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Center,
    Title
} from '@mantine/core';

import {
    PacFormikCreatableSelect,
    PacFormikDropdown,
    PacFormikInput,
    PacFormikRadioCardGroup,
    PacFormikRadioGroup,
    PacFormikTextarea,
    PlatformFieldset
} from '@pac/platform-ui-components';
import {
    IdentityProvider,
    IdentityProviderStatus,
    IdentityProviderType
} from "../types.ts";

import identitySourceOptions from "../../../config/identity-sources.ts";

export const FormComponent = ({values}: FormikProps<IdentityProvider>) => {

    const options = identitySourceOptions.userPools.map(option => {
        return {
            value: option.arn,
            label: option.name
        };
    });

    return (
        <>
            <PlatformFieldset legend={'Identity Provider'}>


                <Field
                    name='name'
                    label='Identity Provider Name'
                    placeholder={I18n.get('Identity Provider Name')}
                    component={PacFormikInput}
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Description'
                    placeholder={I18n.get('Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />

            </PlatformFieldset>
            <PlatformFieldset legend={'Provider Source'}>

                <Field
                    name='providerType'
                    label='Identity Provider Type'
                    placeholder={I18n.get('Identity Provider Type')}
                    component={PacFormikRadioCardGroup}
                    data={[
                        {
                            value: IdentityProviderType.AMAZON_COGNITO,
                            label: 'Amazon Cognito'
                        },
                        {
                            value: IdentityProviderType.EXTERNAL_PROVIDER,
                            label: 'External Provider'
                        }
                    ]}
                    my={'xl'}
                />

                {values.providerType && values.providerType === IdentityProviderType.AMAZON_COGNITO ?
                    <>

                        <Field
                            name="userPoolArn"
                            label={'User Pool'}
                            data={options}
                            component={PacFormikDropdown}
                            withAsterisk
                            my={'xl'}
                        />

                        {values.userPoolArn ? <Field
                            name="clientIds"
                            label={'Client IDs'}
                            component={PacFormikCreatableSelect}
                            my={'xl'}
                        /> : null}


                    </> :

                    <Center my={'xl'}>
                        <Title order={3}>Not implemented</Title>
                    </Center>
                }

            </PlatformFieldset>

            <PlatformFieldset legend={'Status'}>
                <Field
                    name='status'
                    label={I18n.get('Provider Status')}
                    placeholder={I18n.get('Provider Status')}
                    component={PacFormikRadioGroup}
                    options={[
                        {
                            value: IdentityProviderStatus.ACTIVE,
                            label: I18n.get('Active')
                        },
                        {
                            value: IdentityProviderStatus.INACTIVE,
                            label: I18n.get('Inactive')
                        }
                    ]}
                    my={'xl'}
                />

            </PlatformFieldset>
        </>
    );
};

