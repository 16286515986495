import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper,
} from '@pac/platform-ui-components';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";
import {useEditPageTools} from "../hooks/useEditPageTools";
import {usePolicyStore} from "../hooks/usePolicyStore";
import {Grid} from "@mantine/core";
import {EditPageSegmentsNavigationControl} from "../navigation/EditPageSegmentsNavigationControl.tsx";
import {useDocumentTitle} from "@mantine/hooks";


export const EditPage = () => {

    const {
        id: policyStoreId,
    } = useParams();


    const {
        record: policyStore,
        handleFetchRecord,
        handleUpdateRecord
    } = usePolicyStore(policyStoreId);

    useDocumentTitle(`Policy Store: ${policyStore?.name}`);

    const {
        mode,
        tools
    } = useEditPageTools();


    useEffect(() => {
        handleFetchRecord();
    }, [policyStoreId]);


    if (typeof policyStore === 'undefined') {
        return <PlatformLoader message='Loading policy store...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Policy Store: ${policyStore.name}`)}
            pageNavigation={<EditPageNavigation record={policyStore}/>}
            tools={tools}
        >

            {mode === 'view' ?


                <Grid>
                    <Grid.Col span={{
                        base: 4,
                        sm: 12
                    }}>

                        <Profile record={policyStore}/>

                    </Grid.Col>
                    <Grid.Col span={{
                        base: 8,
                        sm: 12
                    }}>

                        <EditPageSegmentsNavigationControl/>
                    </Grid.Col>
                </Grid>

                :
                <SimplePageContentWrapper>
                    <RecordForm
                        record={policyStore}
                        handleSubmit={handleUpdateRecord}
                        handleImport={() => null}

                    />
                </SimplePageContentWrapper>}


        </FormPageLayout>
    );
};
