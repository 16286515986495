import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@pac/platform-ui-components';

import {useNavigate} from 'react-router-dom';

import {stage} from '../../../config/stage';
import {
    PolicyStore,
    PolicyStoreFormValues
} from "../types.ts";
import {useDeploymentEnvironments} from "../../deployment-environments";
import {useEffect} from "react";
import {DeploymentEnvironment} from '../../deployment-environments/types.ts';


export const RecordForm = ({
                               record,
                               handleSubmit,
                               handleImport
                           }: {
    record: PolicyStore,
    handleSubmit: (values: PolicyStoreFormValues) => void,
    handleImport: (values: any) => void
}) => {

    const navigate = useNavigate();

    const {
        items: deploymentEnvironments,
        handleFetchRecords
    } = useDeploymentEnvironments();

    useEffect(() => {
        handleFetchRecords();
    }, []);

    const onSubmit = (values: any) => {
        const {
            policyStoreAction,
            useDefaultDeploymentEnvironment,
            ...rest
        } = values

        // find default deployment environment
        if (useDefaultDeploymentEnvironment) {
            const defaultDeploymentEnvironment = deploymentEnvironments.find((de: DeploymentEnvironment) => de.isDefault);
            if (defaultDeploymentEnvironment) {
                rest.deploymentEnvironmentId = defaultDeploymentEnvironment.id;
            }
        }

        if (rest.existingPolicyStoreId) {
            handleImport({
                             deploymentEnvironmentId: rest.deploymentEnvironmentId,
                             id: rest.existingPolicyStoreId
                         });
        } else {
            handleSubmit(rest);
        }

        navigate(`/policy-stores`);
    };

    const onCancel = () => {
        navigate(`/policy-stores`);
    };


    let initialValues = {
        policyStoreAction: 'create',
        useDefaultDeploymentEnvironment: true,
        validationSettings: {
            mode: 'OFF'
        }
    } as PolicyStoreFormValues;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent handleSubmit={handleSubmit} isSubmitting={isSubmitting} {...rest}/>
                </FormWithButtons>)}
        </Formik>
    );
};
