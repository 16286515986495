import {
    Field,
    FormikProps
} from 'formik';

import {
    Center,
    Fieldset,
    Stack,
    Text,
    Title
} from '@mantine/core';

import {
    PacFormikCreatableSelect,
    PacFormikDropdown,
    PacFormikRadioCardGroup
} from '@pac/platform-ui-components';
import {IdentitySource} from "../types.ts";
import identitySourceOptions from "../../../config/identity-sources.ts";
import {useEffect} from "react";
import {useIdentityProviders} from "../../identity-providers";
import {
    IdentityProvider,
    IdentityProviderType
} from "../../identity-providers/types.ts";
import {EntityTypeNamesAutocomplete} from "../../entity-types/inputs/EntityTypeNamesAutocomplete.tsx";

export const FormComponent = ({
                                  values,
                                  setFieldValue
                              }: FormikProps<IdentitySource>) => {

    // const editing =
    //     typeof values !== 'undefined' &&
    //     Object.prototype.hasOwnProperty.call(values, 'id') &&
    //     typeof values.id !== 'undefined' &&
    //     values.id !== '' && values.id !== null
    //         ? true
    //         : false;


    const options = identitySourceOptions.userPools.map(option => {
        return {
            value: option.arn,
            label: option.name
        };
    });

    const {
        items: identityProviders,
        handleFetchRecords
    } = useIdentityProviders()


    useEffect(() => {
        handleFetchRecords()
    }, [])

    return (
        <Stack gap={'xl'}>

            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Identity Provider</Text>}>

                <Field
                    name="providerType"
                    label={'Identity Provider'}
                    onChange={(value: string) => setFieldValue('identityProvider', value)}
                    component={PacFormikRadioCardGroup}
                    data={identityProviders.map((provider: IdentityProvider) => {
                        return {
                            value: provider.providerType,
                            label: provider.name,
                            description: provider.description
                        };
                    })}
                    my={'xl'}
                />

            </Fieldset>

            {values.providerType && values.providerType === IdentityProviderType.AMAZON_COGNITO ?
                <Fieldset legend={<Text fw={800} tt={'uppercase'}>Amazon Cognito Details</Text>}>

                    <Field
                        name="userPoolArn"
                        label={'User Pool'}
                        data={options}
                        component={PacFormikDropdown}
                        withAsterisk
                        my={'xl'}
                    />

                    {values.userPoolArn ? <Field
                        name="clientIds"
                        label={'Client IDs'}
                        component={PacFormikCreatableSelect}
                        my={'xl'}
                    /> : null}

                    <Field
                        name="principalEntityType"
                        label={'Principal Entity Type'}
                        component={EntityTypeNamesAutocomplete}
                        my={'xl'}
                    />

                    <Field
                        name="groupEntityType"
                        label={'Group Entity Type'}
                        component={EntityTypeNamesAutocomplete}
                        my={'xl'}
                    />

                </Fieldset> :

                <Fieldset legend={<Text fw={800} tt={'uppercase'}>Open ID Connect Details</Text>}>
                    <Center my={'xl'}>
                        <Title order={3}>Not implemented</Title>
                    </Center>
                </Fieldset>
            }


        </Stack>
    );
};

