import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    bulkAdd,
    bulkDelete,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../actions/services/ActionsService';

import {
    deleteManyCase,
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveManyCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTAction} from "../types.ts";
import {moduleConfig} from "../config";
import getUuid from "uuid-by-string";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: NEXTAction) => {
    return Object.assign({}, record, {id: getUuid(record.name)});
};


// genenrates pending, fulfilled and rejected
export const fetchActions = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (policyStoreId: string) => {

        return fetchRecords(policyStoreId)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return response.map((record) => {
                        return normalizeRecord(record)
                    });
                }
            });
    },
);

export const fetchAction = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         policyStoreId,
         id
     }: {
        policyStoreId: string,
        id: string
    }) => {
        return fetch(policyStoreId, id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response)
                }
            });
    });


export const saveAction = createAsyncThunk(
    `${entityKey}/add`,
    ({
         policyStoreId,
         payload
     }: {
        policyStoreId: string,
        payload: NEXTAction
    }) => {

        const updated: NEXTAction = {
            ...payload,
            policyStoreId: policyStoreId,
        }

        return add(updated)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response)
                }
            });
    });

export const updateAction = createAsyncThunk(
    `${entityKey}/update`,
    ({
         policyStoreId,
         id,
         record
     }: {
        policyStoreId: string,
        id: string,
        record: NEXTAction
    }) => {

        const updated: NEXTAction = {
            ...record,
            policyStoreId: policyStoreId,
        }

        return update(id, updated)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response)
                }
            });
    },
);

export const deleteAction = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         policyStoreId,
         name
     }: {
        policyStoreId: string,
        name: string
    }) => {
        return deleteRecord(policyStoreId, name)
            .then(() => getUuid(name));
    },
);


export const bulkSaveActions = createAsyncThunk(
    `${entityKey}/bulkAdd`,
    ({
         policyStoreId,
         actions
     }: {
        policyStoreId: string,
        actions: NEXTAction[]
    }) => {

        return bulkAdd(policyStoreId, actions)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return response.map((record) => {
                        return normalizeRecord(record)
                    });
                }
            });
    });

export const bulkDeleteActions = createAsyncThunk(
    `${entityKey}/bulkDelete`,
    ({
         policyStoreId,
         actions
     }: {
        policyStoreId: string,
        actions: NEXTAction[]
    }) => {

        return bulkDelete(policyStoreId, actions)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return response.map((record) => {
                        return getUuid(record.name)
                    });
                }
            });
    });

const actionsSlice = createSlice({
                                     name: entityKey,
                                     initialState,
                                     reducers: {},
                                     extraReducers: (builder) => {
                                         // FETCH MANY
                                         fetchManyCase(builder, fetchActions, entityKey);

                                         // FETCH ONE
                                         fetchOneCase(builder, fetchAction);

                                         // SAVE ONE
                                         saveOneCase(builder, saveAction);

                                         // BULK SAVE MANY
                                         saveManyCase(builder, bulkSaveActions, entityKey);

                                         // UPDATE ONE
                                         updateOneCase(builder, updateAction);

                                         // DELETE ONE
                                         deleteOneCase(builder, deleteAction);

                                         // BULK DELETE MANY
                                         deleteManyCase(builder, bulkDeleteActions);

                                     },
                                 });

export default actionsSlice.reducer;
