import {
    FunctionComponent,
    useEffect
} from 'react';

import {useParams} from 'react-router-dom';

import {
    Grid,
    Stack
} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {useDataSource} from '../hooks/useDataSource';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";
import {useDocumentTitle} from "@mantine/hooks";

export const EditPage: FunctionComponent = () => {

    const {
        id: dataSourceId
    } = useParams();

    const {
        record: dataSource,
        handleFetchRecord,
        handleUpdateRecord
    } = useDataSource(dataSourceId);

    useDocumentTitle(`Data Source: ${dataSource?.name}`);

    const {
        mode,
        tools
    } = useEditPageTools();


    useEffect(() => {
        handleFetchRecord()
    }, [])


    if (!dataSource) {
        return <PlatformLoader message='Loading permission profile...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={dataSource.name}
            pageNavigation={<EditPageNavigation record={dataSource}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Grid>
                    <Grid.Col span={{
                        base: 4,
                        sm: 12
                    }}>

                        <Profile record={dataSource}/>

                    </Grid.Col>
                    <Grid.Col span={{
                        base: 8,
                        sm: 12
                    }}>


                        <Stack gap='lg'>


                        </Stack>


                    </Grid.Col>
                </Grid>
                :
                <SimplePageContentWrapper>
                    <RecordForm record={dataSource} handleSubmit={handleUpdateRecord}/>
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
