import {
    FunctionComponent,
    useEffect
} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {usePolicyStoreSchema} from '../hooks/usePolicyStoreSchema';
import {Profile} from "../profiles/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";
import {useDocumentTitle} from "@mantine/hooks";

export const EditPage: FunctionComponent = () => {

    const {
        id: policyStoreId,
    } = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record: policyStoreSchema,
        handleFetchRecord,
        handleUpdateRecord
    } = usePolicyStoreSchema(policyStoreId);

    useEffect(() => {
        handleFetchRecord();
    }, []);

    useDocumentTitle(`Schema: ${policyStoreSchema?.policyStoreId}`);

    const {
        mode,
        tools
    } = useEditPageTools();


    if (!policyStoreSchema) {
        return <PlatformLoader message='Loading policy store schema...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={'Policy Store Schema'}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile record={policyStoreSchema}/>
                </Stack>
                :
                <SimplePageContentWrapper>
                    <RecordForm record={policyStoreSchema} handleSubmit={handleUpdateRecord}/>
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
