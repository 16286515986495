import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/AddPageNavigation.tsx";
import {usePermissionGroup} from "../hooks/usePermissionGroup";
import {useParams} from "react-router-dom";
import {useDocumentTitle} from "@mantine/hooks";

export const AddPage: FunctionComponent = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        handleCreateRecord
    } = usePermissionGroup(policyStoreId);

    
    useDocumentTitle('Add Permission Group')


    return (
        <FormPageLayout
            title='Add Permission Group'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={handleCreateRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
