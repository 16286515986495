export const moduleConfig = {
    entityKey: 'policies'
}

export const policyNamespace = 'Console'


export const initialSortState = {
    column: 'createdDate',
    direction: 'desc',
};