import {createSlice} from '@reduxjs/toolkit';
import {IVisibilitySlice} from "../types";


const initialState: IVisibilitySlice = {
    keyword: '',
    visible: false,
    columns: [],
};

export const VisibilitySliceFor = (entityKey: string) => {
    const visibilitySlice = createSlice({
                                            name: entityKey,
                                            initialState,
                                            reducers: {
                                                setKeyword: (state, action) => {
                                                    state.keyword = action.payload;
                                                },
                                                clearKeyword: (state) => {
                                                    state.keyword = '';
                                                },
                                                show: (state) => {
                                                    state.visible = true;
                                                },
                                                hide: (state) => {
                                                    state.visible = false;
                                                },
                                                toggleVisibility: (state) => {
                                                    state.visible = !state.visible;
                                                },
                                                setTableColumns: (state, action) => {
                                                    state.columns = action.payload;
                                                },
                                                addTableColumn: (state, action) => {
                                                    state.columns.push(action.payload);
                                                },
                                            },
                                        });

    return visibilitySlice;
};
