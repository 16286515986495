import {
    deletePolicyStoreSchema,
    fetchPolicyStoreSchema,
    savePolicyStoreSchema,
    updatePolicyStoreSchema
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTPolicyStoreSchema} from "../types.ts";

export const usePolicyStoreSchema = (policyStoreId: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, policyStoreId),
    );


    // FETCH
    const handleFetchRecord = () => {
        dispatch(fetchPolicyStoreSchema(policyStoreId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: NEXTPolicyStoreSchema) => {
        dispatch(savePolicyStoreSchema(values));
    };

    const handleUpdateRecord = (values: NEXTPolicyStoreSchema) => {
        dispatch(updatePolicyStoreSchema(values));
    };

    const handleDeleteRecord = () => {
        dispatch(deletePolicyStoreSchema(policyStoreId));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
