import {I18n} from '@aws-amplify/core';

import {NavLink} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {
    IPolicyTemplateTableColumn,
    PolicyTemplate
} from "../types.ts";

export const columns: IPolicyTemplateTableColumn[] = [
    {
        title: I18n.get('Policy Template ID'),
        render: (item: PolicyTemplate) => {
            return (
                <NavLink
                    to={{
                        pathname: `/policy-stores/${item.policyStoreId}/policy-templates/${item.id}`,
                    }}
                >
                    {item.policyTemplateId}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Description'),
        width: '1fr',
        render: (item: PolicyTemplate) => item.description
    },
    {
        title: I18n.get('Created Date'),
        width: '1fr',
        render: (item: PolicyTemplate) => formatTimestamp(item.createdDate),
    },
    {
        title: I18n.get('Last Updated Date'),
        width: '1fr',
        render: (item: PolicyTemplate) => formatTimestamp(item.lastUpdatedDate),
    },
];
