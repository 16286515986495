export const createAction = `
  mutation createAction($input: CreateActionInput!) {
    createAction(input: $input){
      name
      policyStoreId
      memberOf {
        id
      }
      appliesTo {
        resourceTypes
        principalTypes
      }
    }
  }  
`;

export const updateAction = `
  mutation updateAction($input: UpdateActionInput!) {
    updateAction(input: $input){
      name
      policyStoreId
      memberOf {
        id
      }
      appliesTo {
        resourceTypes
        principalTypes
      }
    }
  }
`;

export const deleteAction = `
  mutation deleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input){
      name
    }
  }  
`;

export const bulkCreateActions = `
  mutation bulkCreateActions($input: BulkCreateActionsInput!) {
    bulkCreateActions(input: $input){
        items {
            name
        }
    }
  }  
`;

export const bulkDeleteActions = `
  mutation bulkDeleteActions($input: BulkDeleteActionsInput!) {
    bulkDeleteActions(input: $input){
        items {
            name
        }
    }
  }  
`;
