import {
    deleteIdentitySource,
    fetchIdentitySource,
    saveIdentitySource,
    updateIdentitySource,
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {IdentitySource} from "../types.ts";

export const useIdentitySource = (permissionSetId: string, identitySourceId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof permissionSetId === 'undefined') {
        throw new Error('permissionSetId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, identitySourceId ? identitySourceId : ''),
    );

    // FETCH
    const handleFetchRecord = () => {

        if (typeof identitySourceId === 'undefined') {
            throw new Error('identitySourceId is required parameter')
        }

        dispatch(fetchIdentitySource({
                                         permissionSetId: permissionSetId,
                                         id: identitySourceId
                                     }));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: IdentitySource) => {

        dispatch(saveIdentitySource({
                                        permissionSetId: permissionSetId,
                                        payload: values
                                    }));
    };

    const handleUpdateRecord = (values: IdentitySource) => {

        if (typeof identitySourceId === 'undefined') {
            throw new Error('identitySourceId is required parameter')
        }

        dispatch(updateIdentitySource({
                                          permissionSetId: permissionSetId,
                                          id: identitySourceId,
                                          record: values
                                      }));
    };

    const handleDeleteRecord = () => {

        if (typeof identitySourceId === 'undefined') {
            throw new Error('identitySourceId is required parameter')
        }

        dispatch(deleteIdentitySource({
                                          permissionSetId: permissionSetId,
                                          id: identitySourceId
                                      }));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
