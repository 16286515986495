import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {Stack} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikTextarea,
    PlatformFieldset
} from '@pac/platform-ui-components';
import {EntityTypesAutocomplete} from "../../entity-types/inputs/EntityTypesAutocomplete.tsx";

export const FormComponent = () => {

    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend={'Permission Group Details'}>

                <Field
                    name='name'
                    label='Permission Group Name'
                    placeholder={I18n.get('Permission Group Name')}
                    component={PacFormikInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='entityTypeId'
                    label='Entity Type'
                    placeholder={I18n.get('Entity Type')}
                    component={EntityTypesAutocomplete}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='entityIdentifier'
                    label='Entity Identifier'
                    placeholder={I18n.get('Entity Identifier')}
                    component={PacFormikInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Permission Group Description'
                    placeholder={I18n.get('Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />
            </PlatformFieldset>

        </Stack>
    );
};

