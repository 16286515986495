import {NextReloadButton} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom';
import {usePolicyStoreSchema} from "../hooks/usePolicyStoreSchema.ts";


export const ReloadRecordButton = () => {

    const {id: policyStoreId} = useParams()

    if (!policyStoreId) {
        throw new Error('policyStoreId is required parameter');
    }

    const {handleFetchRecord} = usePolicyStoreSchema(policyStoreId);

    return (
        <NextReloadButton
            onClick={() => {
                handleFetchRecord();
            }}
        />
    );
};

