import {RootState} from "../../../store";
import {moduleConfig} from "../config";
import {createSelector} from "@reduxjs/toolkit";

const key = moduleConfig.entityKey;

const column = (state: RootState) => state[key].sorting.column;
const direction = (state: RootState) => state[key].sorting.direction;

export const sortSelector = createSelector([column, direction], (column, direction) => {
    return {
        column,
        direction,
    };
});

