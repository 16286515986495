import {
    getDeploymentEnvironment,
    listDeploymentEnvironments
} from './queries';

import {
    createDeploymentEnvironment,
    deleteDeploymentEnvironment,
    updateDeploymentEnvironment
} from './mutations';

import {
    CreateDeploymentEnvironment,
    DeleteDeploymentEnvironment,
    DeploymentEnvironment,
    GetDeploymentEnvironment,
    ListDeploymentEnvironments,
    UpdateDeploymentEnvironment
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();


export const fetchRecords = async (query: { [k: string]: any }) => {

    let filter = {}
    if (query.filter) {
        filter = query.filter
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListDeploymentEnvironments>>(
            {
                query: listDeploymentEnvironments,
                variables: {
                    filter: filter,
                }
            });

        return response.data?.listDeploymentEnvironments.items;
    } catch (e: any) {
        notifyError(e);
    }
};


export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetDeploymentEnvironment>>({
                                                                                          query: getDeploymentEnvironment,
                                                                                          variables: {
                                                                                              id: id
                                                                                          },
                                                                                      });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getDeploymentEnvironment;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: DeploymentEnvironment) => {

    console.debug('FORM PAYLOAD', payload)


    const variables = Object.assign({}, {
        input: {
            platformAccountId: payload.platformAccountId,
            region: payload.region
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreateDeploymentEnvironment>>({
                                                                                             query: createDeploymentEnvironment,
                                                                                             variables: variables,
                                                                                         });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Deployment Environment created');
            return response.data.createDeploymentEnvironment;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (id: string, record: DeploymentEnvironment) => {

    console.debug('IN UPDATE ENTITY TYPE SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: record.id,
            platformAccountId: record.platformAccountId,
            region: record.region,
            isDefault: record.isDefault
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateDeploymentEnvironment>>({
                                                                                             query: updateDeploymentEnvironment,
                                                                                             variables: variables,
                                                                                         });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Deployment Environment updated');
            return response.data.updateDeploymentEnvironment;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteDeploymentEnvironment>>({
                                                                                             query: deleteDeploymentEnvironment,
                                                                                             variables: {
                                                                                                 input: {
                                                                                                     id: id
                                                                                                 }
                                                                                             },
                                                                                         });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Deployment Environment deleted');
            return response.data.deleteDeploymentEnvironment;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
