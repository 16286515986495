import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string(),
                                          description: Yup.string(),
                                          validationSettings: Yup.object({
                                                                             mode: Yup.string()
                                                                         }),
                                      });
