import {Formik} from 'formik';

import {BulkRecordSchema} from './BulkRecordSchema';

import {BulkFormComponent} from './BulkFormComponent';

import {
    DisplayFormikState,
    FormWithActionBar,
} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {FormValuesToActionsPayload} from './BulkActionsValueHelper';

import {stage} from '../../../../config/stage';
import {NEXTAction} from "../../types.ts";


type BulkRecordFormValues = {
    resourceNameSingular: string,
    resourceNamePlural: string,
    appliesTo: {
        resourceTypes: string[],
        principalTypes: string[],
    }
}

export const BulkRecordForm = (
    {
        record,
        handleSubmit
    }: {
        record: NEXTAction,
        handleSubmit: (values: NEXTAction[]) => void
    }) => {

    const navigate = useNavigate();

    const {id: policyStoreId} = useParams();

    const onSubmit = (values: BulkRecordFormValues) => {

        const actions = FormValuesToActionsPayload(values) as { [k: string]: NEXTAction };
        const actionCollection = Object.values(actions);
        handleSubmit(actionCollection);

        navigate(`/policy-stores/${policyStoreId}/schema/#actions`);
    };

    const renderForm = (props: {
        values: {
            resourceNameSingular: string,
            resourceNamePlural: string,
            appliesTo: {
                resourceTypes: string[],
                principalTypes: string[],
            }
        }, handleSubmit: () => void
    }) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <BulkFormComponent {...props} />
                {false && stage === 'ide' && (
                    <DisplayFormikState {...props} />
                )}
            </FormWithActionBar>
        );
    };

    let initialValues = {
        resourceNameSingular: '',
        resourceNamePlural: '',
        appliesTo: {
            resourceTypes: [],
            principalTypes: [],
        }
    } as BulkRecordFormValues;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BulkRecordSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

