import {
    Center,
    Group,
    Text
} from "@mantine/core";


import {modals} from '@mantine/modals';

import {AiOutlineWarning} from "react-icons/ai";

export const openDeleteConfirmationModal = (handler: () => void) => modals.openConfirmModal(
    {
        title: 'Confirm Deletion',
        size: 'lg',
        children: (
            <Center p={'sm'}>
                <Group>
                    <AiOutlineWarning
                        size={'2.5em'}
                        color="orange"/>
                    <Text
                        size="sm">This action will delete selected record(s)?</Text>
                </Group>
            </Center>
        ),
        labels: {
            confirm: 'Confirm Delete',
            cancel: 'Cancel'
        },
        onCancel: () => {
            return;
        },
        onConfirm: handler,
    });




