import {useEffect} from 'react';

import {
    SimpleGrid,
    Table
} from '@mantine/core';

import {useActions} from '../hooks/useActions';

import {
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@pac/platform-ui-components';
import {useParams} from "react-router-dom";
import {useRecordsTableColumns} from "../hooks/useRecordsTableColumns.tsx";
import {useActionsSelection} from "../hooks/useActionsSelection.ts";
import {useActionsSearch} from "../hooks/useActionsSearch.ts";
import {useActionsPaging} from "../hooks/useActionsPaging.ts";

type RecordsTableProps = {
    extraTools?: JSX.Element[],
    settings?: { label: string, onClick: () => void }[]
}
export const RecordsTable = ({
                                 extraTools,
                                 settings
                             }: RecordsTableProps) => {


    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const {
        visibleItems,
        loading,
        handleFetchRecords,
    } = useActions(policyStoreId);

    const {
        selection,
        handleSelectAllRows,
    } = useActionsSelection(policyStoreId);

    const {
        handleFilterByKeyword,
    } = useActionsSearch(policyStoreId);


    const {
        limit,
        totalPages,
        visibleCount,
        handleItemsPerPageChange,
        handlePageChange,
    } = useActionsPaging(policyStoreId);

    const {
        columns,
    } = useRecordsTableColumns(policyStoreId);


    useEffect(() => {
        handleFetchRecords();
    }, []);


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={columns as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={columns as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                totalCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
