import {
    ReactNode,
    useEffect,
    useState
} from "react";
import {CognitoContext} from "../context/CognitoContext";
import {Hub} from "aws-amplify/utils";
import {signInWithRedirect} from "aws-amplify/auth";
import {modals} from "@mantine/modals";
import {
    Center,
    Group,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {NextButton} from "@pac/platform-ui-components";
import {
    AiOutlineLogin,
    AiOutlineWarning
} from "react-icons/ai";
import {notifyError} from "../components/ServiceResponseNotifications.tsx";

type CognitoContextProviderProps = {
    isSignedIn?: boolean;
    children?: ReactNode;
}

// @see this - https://stackoverflow.com/questions/71479240/listen-to-user-inactivity-with-aws-amplify-cognito

export const SessionExpiredMessageComponent = () => (
    <Stack justify={'center'}>
        <Group justify={'center'}>
            <AiOutlineWarning size={50} color="orange"/>
            <Title order={4}><Text>Your session has expired. Please, sign in
                again!</Text></Title>
        </Group>
        <Center>
            <Text size="sm" c="dimmed">You will be redirected to the login page.</Text>
        </Center>
        <Center>
            <NextButton
                leftSection={<AiOutlineLogin/>}
                onClick={() => {
                    try {
                        signInWithRedirect()
                    } catch (e) {
                        notifyError(e)
                        console.debug(e)
                    }
                    // modals.closeAll()
                }}
                mt="md">Sign In</NextButton>
        </Center>
    </Stack>
)


export const CognitoContextProvider = ({children}: CognitoContextProviderProps) => {

    const [context, setContext] = useState({isSignedIn: false});

    useEffect(() => {
        const authListener = (
            {
                channel,
                payload: {event}
            }: {
                channel: string,
                payload: {
                    event: string,
                },
            }
        ) => {

            if (channel === 'auth') {

                console.debug('IN COGNITO CONTEXT PROVIDER. AUTH EVENT ', event);

                switch (event) {
                    case 'signIn':
                        setContext(prevState => (prevState.isSignedIn ? prevState : {
                            ...prevState,
                            isSignedIn: true
                        }));
                        break;
                    case 'tokenRefresh_failure':
                        modals.open({
                                        title: 'Session Expired',
                                        size: 'xl',
                                        children: (
                                            <SessionExpiredMessageComponent/>
                                        ),
                                    });
                        setTimeout(() => {
                            signInWithRedirect()
                        }, 1000);
                        break;
                }
            }
        };

        const handle = Hub.listen('auth', authListener);

        return handle

    }, []);

    return (
        <CognitoContext.Provider value={context}>
            {children}
        </CognitoContext.Provider>
    )
}