import {
    deleteAction,
    fetchAction,
    saveAction,
    updateAction
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTAction} from "../types.ts";

export const useAction = (policyStoreId: string, actionId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, actionId ? actionId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof actionId === 'undefined') {
            throw new Error('actionId is required parameter')
        }

        dispatch(fetchAction({
                                 policyStoreId,
                                 id: actionId
                             }));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: NEXTAction) => {
        dispatch(saveAction({
                                policyStoreId,
                                payload: values
                            }));
    };

    const handleUpdateRecord = (values: NEXTAction) => {

        if (typeof actionId === 'undefined') {
            throw new Error('actionId is required parameter')
        }

        dispatch(updateAction({
                                  policyStoreId,
                                  id: actionId,
                                  record: values
                              }));
    };

    const handleDeleteRecord = () => {

        if (typeof actionId === 'undefined') {
            throw new Error('actionId is required parameter')
        }

        dispatch(deleteAction({
                                  policyStoreId,
                                  name: actionId
                              }));
    };

    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord
    };
};
