import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../permission-groups/services/PermissionGroupsService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {PermissionGroup} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: PermissionGroup) => {
    return Object.assign({}, record);
};

// genenrates pending, fulfilled and rejected
export const fetchPermissionGroups = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    ({
         policyStoreId
     }: {
        policyStoreId: string
    }) => {
        return fetchRecords(policyStoreId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchPermissionGroup = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         policyStoreId,
         id
     }: {
        policyStoreId: string,
        id: string
    }) => {
        return fetch(policyStoreId, id)
            .then((response) => {
                return normalizeRecord(response as PermissionGroup);
            });
    });


export const savePermissionGroup = createAsyncThunk(
    `${entityKey}/add`,
    ({
         policyStoreId,
         payload
     }: {
        policyStoreId: string,
        payload: PermissionGroup
    }) => {
        return add(policyStoreId, payload)
            .then((response) => normalizeRecord(response as PermissionGroup));
    });

export const updatePermissionGroup = createAsyncThunk(
    `${entityKey}/update`,
    ({
         policyStoreId,
         id,
         record
     }: {
        policyStoreId: string,
        id: string,
        record: PermissionGroup
    }) => {

        return update(policyStoreId, id, record)
            .then((response) => {
                return normalizeRecord(response as PermissionGroup);
            });
    },
);

export const deletePermissionGroup = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         policyStoreId,
         id
     }: { policyStoreId: string, id: string }) => {
        return deleteRecord(policyStoreId, id)
            .then(() => id);
    },
);

const permissionGroupsSlice = createSlice({
                                              name: entityKey,
                                              initialState,
                                              reducers: {},
                                              extraReducers: (builder) => {
                                                  // FETCH MANY
                                                  fetchManyCase(builder, fetchPermissionGroups, entityKey);

                                                  // FETCH ONE
                                                  fetchOneCase(builder, fetchPermissionGroup);

                                                  // SAVE ONE
                                                  saveOneCase(builder, savePermissionGroup);

                                                  // UPDATE ONE
                                                  updateOneCase(builder, updatePermissionGroup);

                                                  // DELETE ONE
                                                  deleteOneCase(builder, deletePermissionGroup);
                                              },
                                          });

export default permissionGroupsSlice.reducer;
