import {FunctionComponent} from 'react';

import {
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {IndexPage as PermissionGroupsIndex} from "../../permission-groups/pages/IndexPage.tsx";
import {useLocation} from 'react-router-dom';

export const GroupsPageSegment: FunctionComponent = () => {

    const {hash} = useLocation()

    const groupsTabs = [
        {
            title: 'Permission Groups',
            component: <PermissionGroupsIndex/>,
            key: 'permission-groups'
        },

    ] as (PlatformTabComponentTab & { key: string })[]

    const activeTab = groupsTabs.find((tab) => tab.key === hash.substring(1)) || groupsTabs[0]
    activeTab.active = true


    return (
        <SimplePageContentWrapper>
            <PlatformTabbedComponent tabs={groupsTabs}></PlatformTabbedComponent>
        </SimplePageContentWrapper>
    );
};
