import {
    deletePolicyStore,
    fetchPolicyStore,
    importPolicyStore,
    savePolicyStore,
    updatePolicyStore
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {PolicyStore} from "../types.ts";

export const usePolicyStore = (policyStoreId?: string) => {
    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, policyStoreId ? policyStoreId : ''),
    );

    // FETCH
    const handleFetchRecord = () => {

        if (typeof policyStoreId === 'undefined') {
            throw new Error('policyStoreId is required parameter')
        }

        dispatch(fetchPolicyStore(policyStoreId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: PolicyStore) => {
        dispatch(savePolicyStore(values));
    };

    const handleImportRecord = (values: PolicyStore) => {
        dispatch(importPolicyStore(values));
    };

    const handleUpdateRecord = (values: PolicyStore) => {

        if (typeof policyStoreId === 'undefined') {
            throw new Error('policyStoreId is required parameter')
        }

        dispatch(updatePolicyStore({
                                       id: policyStoreId,
                                       record: values
                                   }));
    };

    const handleDeleteRecord = () => {
        if (typeof policyStoreId === 'undefined') {
            throw new Error('policyStoreId is required parameter')
        }

        dispatch(deletePolicyStore(policyStoreId));
    };

    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleImportRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
