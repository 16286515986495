import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Fieldset,
    Stack,
    Text
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup,
    PacFormikTextarea
} from '@pac/platform-ui-components';
import {PolicyStoreFormValues} from "../types.ts";
import {DeploymentEnvironmentsAutocomplete} from "../../deployment-environments/inputs/DeploymentEnvironmentsAutocomplete.tsx";
import {IdentityProvidersAutocomplete} from "../../identity-providers/inputs/IdentityProvidersAutocomplete.tsx";


export const EditFormComponent = (props: FormikProps<PolicyStoreFormValues>) => {

    const {
        values,
    } = props;

    const editing =
        typeof values !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(values, 'id') &&
        typeof values.id !== 'undefined' &&
        values.id !== '' && values.id !== null
            ? true
            : false;

    return (
        <Stack gap={'xl'}>

            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Policy Store Details</Text>}>
                <Field
                    name='name'
                    label={I18n.get('Name')}
                    component={PacFormikInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='description'
                    label={I18n.get('Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />

                <Field
                    name='validationSettings.mode'
                    label={I18n.get('Validation Settings Mode')}
                    component={PacFormikRadioGroup}
                    options={[{
                        label: 'OFF',
                        value: 'OFF'
                    }, {
                        label: 'STRICT',
                        value: 'STRICT'
                    }]}
                    disabled={editing ? true : false}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name="deploymentEnvironmentId"
                    label={'Deployment Environment'}
                    component={DeploymentEnvironmentsAutocomplete}
                    disabled={true}
                    my={'xl'}
                />

            </Fieldset>


            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Identity Provider</Text>}>

                <Field
                    name="identityProviderId"
                    label={'Identity Provider'}
                    component={IdentityProvidersAutocomplete}
                    my={'xl'}
                />

            </Fieldset>

            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Metadata</Text>}>

                <Field
                    name="id"
                    label={'Policy Store ID'}
                    component={PacFormikInput}
                    disabled={true}
                    my={'xl'}
                />

            </Fieldset>

        </Stack>
    );
};
