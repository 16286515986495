import {createSlice} from '@reduxjs/toolkit';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 6;

export const PaginationSliceFor = (entityKey: string, initialState = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE
}) => {

    const slice = createSlice({
                                  name: entityKey,
                                  initialState,
                                  reducers: {
                                      setPage: (state, action) => {
                                          state.page = action.payload;
                                      },
                                      incrementPage: (state) => {
                                          state.page++;
                                      },
                                      decrementPage: (state) => {
                                          state.page--;
                                      },
                                      setPageSize: (state, action) => {
                                          state.limit = action.payload;
                                      },
                                      resetPageSize: (state) => {
                                          state.limit = DEFAULT_PAGE_SIZE;
                                      },
                                  },
                              });

    return slice;
};
