import {useParams} from 'react-router-dom';
import {usePolicyStoreBreadcrumbElements} from "../../policy-stores/hooks/usePolicyStoreBreadcrumbElements.tsx";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";

export const AddPageNavigation = () => {
    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {elements: rootPathElements} = usePolicyStoreBreadcrumbElements(policyStoreId)

    const elements = rootPathElements.concat([
                                                 {title: 'Add Entity Type'}
                                             ]);
    return renderBreadcrumbs(elements)
};
