import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    fetchManyWithTokenPaginationCase,
    fetchOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {moduleConfig} from "../config";
import {IListRecordsQuery} from "../../../types";
import {visibilitySelector} from "../selectors.ts";
import {
    fetch,
    fetchRecords
} from "../services/AvpPolicyStoresService.ts";
import {AvpPolicyStore} from "../types.ts";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: AvpPolicyStore) => {
    return Object.assign({}, record, {
        id: record.policyStoreId
    });
};

// genenrates pending, fulfilled and rejected
export const fetchAvpPolicyStores = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (deploymentEnvironmentId: string, {getState}) => {

        const state = getState()

        const {
            limit,
            nextToken,
            keyword
        } = visibilitySelector(state)


        let listQuery = {
            deploymentEnvironmentId: deploymentEnvironmentId,
            limit: limit,
            nextToken: nextToken,
        } as IListRecordsQuery;

        if (keyword) {
            listQuery = {
                keyword,
                ...listQuery,
            };
        }


        return fetchRecords(listQuery as IListRecordsQuery & { deploymentEnvironmentId: string })
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return {
                        items: response.items.map((record: AvpPolicyStore) => normalizeRecord(record)),
                        nextToken: response.nextToken,
                    };
                }
            })
    },
);

export const fetchAvpPolicyStore = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         deploymentEnvironmentId,
         id
     }: {
        deploymentEnvironmentId: string,
        id: string
    }) => {
        return fetch(deploymentEnvironmentId, id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });


const policyStoresSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyWithTokenPaginationCase(builder, fetchAvpPolicyStores, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchAvpPolicyStore);

                                          },
                                      });

export default policyStoresSlice.reducer;
