import {getPolicyStoreSchema} from './queries';

import {
    createPolicyStoreSchema,
    deletePolicyStoreSchema,
    updatePolicyStoreSchema
} from './mutations';

import {
    CreatePolicyStoreSchema,
    DeletePolicyStoreSchema,
    GetPolicyStoreSchema,
    NEXTPolicyStoreSchema,
    UpdatePolicyStoreSchema
} from "../types.ts";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";

const client = generateClient();

export const fetch = async (policyStoreId: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPolicyStoreSchema>>({
                                                                                      query: getPolicyStoreSchema,
                                                                                      variables: {
                                                                                          policyStoreId: policyStoreId
                                                                                      },
                                                                                  });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPolicyStoreSchema;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: NEXTPolicyStoreSchema) => {

    const variables = Object.assign({}, {
        input: {
            policyStoreId: payload.policyStoreId,
            schema: payload.schema
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreatePolicyStoreSchema>>({
                                                                                         query: createPolicyStoreSchema,
                                                                                         variables: variables,
                                                                                     });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Schema created');
            return response.data.createPolicyStoreSchema;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e)
    }
};

export const update = async (record: NEXTPolicyStoreSchema) => {

    const variables = Object.assign({}, {
        input: {
            policyStoreId: record.policyStoreId,
            schema: record.schema
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePolicyStoreSchema>>({
                                                                                         query: updatePolicyStoreSchema,
                                                                                         variables: variables,
                                                                                     });

        notifySuccess('Schema updated');
        return response.data?.updatePolicyStoreSchema;


    } catch (e: any) {

        if (e.errors) {
            e.errors.forEach((error: any) => {
                notifyError(error);
            })
        }
        notifyError(e);
    }
};

export const deleteRecord = async (policyStoreId: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePolicyStoreSchema>>({
                                                                                         query: deletePolicyStoreSchema,
                                                                                         variables: {
                                                                                             input: {
                                                                                                 policyStoreId: policyStoreId
                                                                                             }
                                                                                         },
                                                                                     });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Schema deleted');
            return response.data.deletePolicyStoreSchema;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
