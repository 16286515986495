import {PolicyStore} from "../types.ts";
import {
    renderBreadcrumbs,
    rootPathElements
} from "./CommonPageNavigation.tsx";


export const EditPageNavigation = ({record}: { record: PolicyStore }) => {
    const elements = rootPathElements()
        .concat([{
            title: record.name,
            href: `/policy-stores/${record.id}/permissions`
        }]);
    return renderBreadcrumbs(elements)
};
