import {useParams} from 'react-router-dom';
import {BreadcrumbElement,} from "../../../types";
import {usePolicyStoreBreadcrumbElements} from "../../policy-stores/hooks/usePolicyStoreBreadcrumbElements.tsx";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";


export const AddPageNavigation = () => {
    const {id: permissionSetId} = useParams();

    if (typeof permissionSetId === 'undefined') {
        throw new Error('permissionSetId is required parameter')
    }

    const {elements: rootPathElements} = usePolicyStoreBreadcrumbElements(permissionSetId)

    const elements: BreadcrumbElement[] = rootPathElements.concat([
                                                                      {title: 'IdentitySources'},
                                                                      {title: 'Add IdentitySource'}
                                                                  ]);
    return renderBreadcrumbs(elements)
};

