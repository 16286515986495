import {NEXTPolicy} from "../types.ts";
import {usePolicyStoreBreadcrumbElements} from "../../policy-stores/hooks/usePolicyStoreBreadcrumbElements.tsx";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";


export const EditPageNavigation = ({record}: { record: NEXTPolicy }) => {
    const {elements: rootPathElements} = usePolicyStoreBreadcrumbElements(record.policyStoreId)

    const elements = rootPathElements.concat([
                                                 {title: 'Policies'},
                                                 {title: record.policyId}
                                             ]);
    return renderBreadcrumbs(elements)
};
