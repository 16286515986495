import {BreadcrumbElement} from "../../../types";
import {
    useEffect,
    useState
} from "react";
import {usePolicyStore} from "./usePolicyStore.ts";

export const usePolicyStoreBreadcrumbElements = (policyStoreId: string) => {

    const [elements, setElements] = useState<BreadcrumbElement[]>([]);
    const {
        record,
        handleFetchRecord
    } = usePolicyStore(policyStoreId);

    useEffect(() => {
        handleFetchRecord();
    }, []);

    useEffect(() => {
        if (record) {
            const els = [
                {
                    title: 'Home',
                    href: '/dashboard'
                },
                {
                    title: 'Policy Store',
                    href: `/policy-stores`
                },
                {
                    title: record.name,
                    href: `/policy-stores/${record.id}/permissions`
                }
            ]
            setElements(els);
        }
    }, [record]);


    return {
        elements
    }
}