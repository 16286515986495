import {I18n} from '@aws-amplify/core';

import {NextEditButton} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {usePermissionGroupsSelection} from "../hooks/usePermissionGroupsSelection.ts";


export const EditRecordButton = () => {
    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const navigate = useNavigate();
    const {selection} = usePermissionGroupsSelection(policyStoreId);
    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length === 1) {
        disabled = false
    }
    const recordId = recordIds.shift()

    return (
        <NextEditButton
            disabled={disabled}
            onClick={() => {
                navigate(`/applications/${recordId}`);
            }}
        >
            {I18n.get('Edit')}
        </NextEditButton>
    );
};
