import {createContext} from 'react';


type CognitoContextType = {
    isSignedIn: boolean;
}

export const CognitoContext = createContext<CognitoContextType>({
                                                                    isSignedIn: false,
                                                                });


