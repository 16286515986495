import {Stack} from "@mantine/core";
import {PolicyStore} from "../types.ts";
import {
    PlatformPropertiesCard,
    PlatformPropertiesCardCopyButtonDecorator,
} from "@pac/platform-ui-components";
import {NavLink} from "react-router-dom";


export const Profile = ({record}: { record: PolicyStore }) => {


    const properties = [
        {
            label: 'Name',
            value: record.name,
            span: 12
        }, {
            label: 'Policy Store ID',
            value: record.id,
            decorator: (value: string) => <PlatformPropertiesCardCopyButtonDecorator value={value}/>,
            span: 12
        }, {
            label: 'Deployment Environment',
            value: <NavLink
                to={`/deployment-environments/${record.deploymentEnvironmentId}`}>{record.deploymentEnvironment ? `${record.deploymentEnvironment.platformAccount?.name} [ ${record.deploymentEnvironment.region} ] ` : record.deploymentEnvironmentId}</NavLink>,
            span: 12
        }, {
            label: 'Identity Provider',
            value: <NavLink
                to={`/identity-providers/${record.identityProviderId}`}>{record.identityProvider ? record.identityProvider.name : record.identityProviderId}</NavLink>,
            span: 12
        }, {
            label: 'Description',
            value: record.description,
            span: 12
        }, {
            label: 'Validation Settings Mode',
            value: record.validationSettings ? record.validationSettings.mode : 'n/a',
            span: 12
        },
    ];

    const properties2 = [
        {
            label: 'Created On',
            value: record.created ? record.created : 'n/a',
            formatAs: 'Timestamp',
            span: 12
        },
        {
            label: 'Created By',
            value: record.createdByUsername,
            span: 12
        },
        {
            label: 'Last Updated Date',
            value: record.updated ? record.updated : 'n/a',
            formatAs: 'Timestamp',
            span: 12
        },
        {
            label: 'Managed By',
            value: record.managedByUsername,
            span: 12
        },
    ];

    return (
        <Stack gap={'xl'}>
            <PlatformPropertiesCard properties={properties}/>
            <PlatformPropertiesCard properties={properties2}/>
        </Stack>
    );
};

