import {combineReducers} from 'redux';

import recordsReducer from './recordsSlice';
import {VisibilitySliceFor} from '../../../store/VisibilitySliceFor';
import {SelectionSliceFor} from '../../../store/SelectionSliceFor';
import {moduleConfig} from "../config";
import {PaginationSliceFor} from "../../../store/PaginationSliceFor.ts";
import {SortingSliceFor} from "../../../store/SortingSliceFor.ts";
import {initialSortState} from "../../policies/config";

const entityKey = moduleConfig.entityKey;

const paginationSlice = PaginationSliceFor(entityKey);
const selectionSlice = SelectionSliceFor(entityKey);
const sortingSlice = SortingSliceFor(entityKey, initialSortState);
const visibilitySlice = VisibilitySliceFor(entityKey);

export const {
    setKeyword,
    clearKeyword,
    show,
    hide,
    toggleVisibility,
    setTableColumns,
    addTableColumn,
} = visibilitySlice.actions;

export const {
    setPage,
    incrementPage,
    decrementPage,
    setPageSize,
    resetPageSize,
} = paginationSlice.actions;

export const {
    addToSelection,
    removeFromSelection,
    clearSelection
} =
    selectionSlice.actions;

export const {
    setSorting,
    setSortingColumn,
    setSortingDirection,
    resetSorting,
} = sortingSlice.actions;


export const PlatformAccountsReducer = combineReducers({
                                                           records: recordsReducer,
                                                           pagination: paginationSlice.reducer,
                                                           selection: selectionSlice.reducer,
                                                           sorting: sortingSlice.reducer,
                                                           visibility: visibilitySlice.reducer,
                                                       });
