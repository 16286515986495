import {useParams} from "react-router-dom";
import {PortalDashboard} from "../components/PortalDashboard.tsx";
import {SimplePageLayout} from "@pac/platform-ui-components";
import {Title} from "@mantine/core";


export const DetailPage = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    return (

        <SimplePageLayout pageHeader={(<Title order={1}>Policy Store Dashboard {policyStoreId}</Title>)}>
            <PortalDashboard/>
        </SimplePageLayout>
    )

};
