import {
    clearKeyword,
    setKeyword,
} from '../reducers/index';

import {visibilitySelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {useSearchParams} from "react-router-dom";

export const usePoliciesSearch = (policyStoreId: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const [searchParams] = useSearchParams()

    const {
        keyword,
    } = useAppSelector((state) => visibilitySelector(state, policyStoreId, searchParams));

    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    return {
        keyword,
        handleFilterByKeyword,
    };
};
