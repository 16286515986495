import {showNotification} from '@mantine/notifications';
import {
    AiOutlineExclamation,
    AiOutlineInfo,
} from 'react-icons/ai';
import {ApiError} from "aws-amplify/api";
import {signInWithRedirect} from "aws-amplify/auth";


export const notifySuccess = (message = 'Operation Success') => {

    showNotification({
                         title: 'Success',
                         icon: <AiOutlineInfo/>,
                         message: message,
                         color: 'green',
                     });

};

export const notifyError = (e: unknown) => {

    console.debug('NOTIFY ERROR CALLED WITH ', {e});

    console.trace(e);

    if (typeof e === 'object' && e !== null && 'message' in e && e.message === 'No federated jwt') {
        showNotification({
                             title: 'Session Expired',
                             icon: <AiOutlineExclamation/>,
                             message: 'Your session has expired. Please sign in again.',
                             color: 'red',
                         });
        setTimeout(() => {
            signInWithRedirect();
        }, 1000);
    }

    if (typeof e === 'string') {
        throw new Error(e);
    }

    if (e instanceof ApiError) {
        if (e.response) {
            const {
                statusCode,
                headers,
                body
            } = e.response;
            console.error(`Received ${statusCode} error response with payload: ${body} and headers: ${headers}`);

            showNotification({
                                 title: 'API Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: body,
                                 color: 'red',
                             });

        }
        // Handle API errors not caused by HTTP response.
    }
    // Handle other errors.

    if (e instanceof Error) {

        const myError = e as Error & { response: { data: { message: string } } };

        let errorMessage = e.message;

        if (typeof myError.response !== 'undefined') {
            if (typeof myError.response.data.message !== 'undefined') {
                errorMessage = myError.response.data.message;
            }
        }

        showNotification({
                             title: 'Error',
                             icon: <AiOutlineExclamation/>,
                             message: errorMessage,
                             color: 'red',
                         });

        throw new Error(e.message);

    }

    if (typeof e === 'object' && e !== null && 'errors' in e && Array.isArray(e.errors)) {
        e.errors.forEach((e: { message: string }) => {
            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: e.message,
                                 color: 'red',
                             });
        })
    }

};

export const notifyAccessDenied = () => {

    showNotification({
                         title: 'Access Denied',
                         icon: <AiOutlineExclamation/>,
                         message: 'You do not have permission to perform this action',
                         color: 'red',
                     });

};
