import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {PacFormikExpandableJsonInput} from '@pac/platform-ui-components';


export const FormComponent = () => {

    return (
        <Field
            name='schema'
            placeholder={I18n.get('Schema')}
            component={PacFormikExpandableJsonInput}
            autosize={true}
            minRows={10}
        />
    );
};
