import {
    clearKeyword,
    setKeyword,
    setPage,
    setPageSize,
} from '../reducers/index';

import {
    deleteTenant,
    fetchTenants,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';
import {useEffect} from "react";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";

export const useTenants = () => {
    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        limit,
        keyword,
        page,
        totalPages,
        visibleCount
    } = useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state)
    );

    useEffect(() => {
        handleFetchRecords();
    }, [dispatch]);

    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchTenants({
                                  'response-group': 'large',
                                  page: 0,
                                  limit: 1000,
                              }));
    };


    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deleteTenant(key));
            }
        }
    };


    // FILTER
    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    return {
        items,
        visibleItems,
        limit,
        loading,
        keyword,
        page,
        totalPages,
        visibleCount,
        handleFetchRecords,
        handleDeleteRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
