import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/AddPageNavigation.tsx";
import {useDeploymentEnvironment} from "../hooks/useDeploymentEnvironment";
import {useDocumentTitle} from "@mantine/hooks";

export const AddPage: FunctionComponent = () => {

    const {
        record,
        handleCreateRecord
    } = useDeploymentEnvironment();

    useDocumentTitle('Add Deployment Environment');
    
    return (
        <FormPageLayout
            title='Add Deployment Environment'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper><RecordForm record={record}
                                                  handleSubmit={handleCreateRecord}/></SimplePageContentWrapper>
        </FormPageLayout>
    );
};
