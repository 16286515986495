import {FunctionComponent} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {useEntityType} from '../hooks/useEntityType';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../components/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";
import {useDocumentTitle} from "@mantine/hooks";

export const EditPage: FunctionComponent = () => {

    const {
        id: policyStoreId,
        entityTypeId
    } = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record: entityType,
        handleUpdateRecord
    } = useEntityType(policyStoreId, entityTypeId);

    useDocumentTitle(`Entity Type: ${entityType?.name}`);

    const {
        mode,
        tools
    } = useEditPageTools();


    if (!entityType) {
        return <PlatformLoader message='Loading entity type...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Entity Type: ${entityType.name}`)}
            pageNavigation={<EditPageNavigation record={entityType}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={entityType}
                    />
                </Stack>
                :
                <SimplePageContentWrapper>
                    <RecordForm record={entityType} handleSubmit={handleUpdateRecord}/>
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
