export const getPolicyStoreSchema = `
  query getPolicyStoreSchema($policyStoreId: ID!) {
    getPolicyStoreSchema(policyStoreId: $policyStoreId){
      schema
      policyStoreId
      policyStore {
        id
        name
      }
    }
  }  
`;