import {
    deleteEntityType,
    fetchEntityType,
    saveEntityType,
    updateEntityType
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTEntityType} from "../types.ts";

export const useEntityType = (policyStoreId: string, entityTypeId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, entityTypeId ? entityTypeId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof entityTypeId === 'undefined') {
            throw new Error('entityTypeId is required parameter')
        }

        dispatch(fetchEntityType(entityTypeId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: NEXTEntityType) => {
        dispatch(saveEntityType(values));
    };

    const handleUpdateRecord = (values: NEXTEntityType) => {

        if (typeof entityTypeId === 'undefined') {
            throw new Error('entityTypeId is required parameter')
        }

        dispatch(updateEntityType({
                                      id: entityTypeId,
                                      record: values
                                  }));
    };

    const handleDeleteRecord = () => {

        if (typeof entityTypeId === 'undefined') {
            throw new Error('entityTypeId is required parameter')
        }

        dispatch(deleteEntityType({
                                      policyStoreId,
                                      name: entityTypeId
                                  }));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
