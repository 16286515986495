import {
    useEffect,
    useState
} from 'react';

import {
    Box,
    Stack
} from '@mantine/core';

import {usePolicies} from '../hooks/usePolicies';

import {
    useParams,
    useSearchParams
} from 'react-router-dom'

import {
    PacTableToolbar,
    PacTableToolbarKeywordFilter,
    PacTableToolbarPageSizeSelector,
} from '@pac/platform-ui-components';
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {usePoliciesSearch} from "../hooks/usePoliciesSearch.ts";
import {usePoliciesPaging} from "../hooks/usePoliciesPaging.ts";
import {usePoliciesSelection} from "../hooks/usePoliciesSelection.ts";
import {useRecordsTableColumns} from "../hooks/useRecordsTableColumns.tsx";
import {usePoliciesSorting} from '../hooks/usePoliciesSorting.ts';
import {
    DataTable,
    DataTableSortStatus
} from 'mantine-datatable';
import {NEXTPolicy} from '../types.ts';


export const RecordsTable = () => {

    const [searchParams] = useSearchParams()

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const {allowedActions} = useVerifiedPermissions()


    const {
        visibleItems,
        loading,
        handleFetchRecords,
    } = usePolicies(policyStoreId);

    const {
        selection,
        handleRowSelection,
        handleClearSelection
    } = usePoliciesSelection(policyStoreId);

    const {
        page,
        limit,
        totalCount,
        handleItemsPerPageChange,
        handlePageChange,
    } = usePoliciesPaging(policyStoreId);

    const {
        keyword,
        handleFilterByKeyword,
    } = usePoliciesSearch(policyStoreId);

    const {
        column: sortColumn,
        direction,
        handleSort,
    } = usePoliciesSorting();

    const {columns} = useRecordsTableColumns()

    let query = {}
    if (searchParams.get('entityType')) {
        if (searchParams.get('element') === 'Principal') {
            query = {
                Principal: {
                    Identifier: {
                        EntityType: searchParams.get('entityType'),
                        EntityId: searchParams.get('entityId')
                    }
                }
            }
        } else if (searchParams.get('element') === 'Resource') {
            query = {
                Resource: {
                    Identifier: {
                        EntityType: searchParams.get('entityType'),
                        EntityId: searchParams.get('entityId')
                    }
                }
            }

        }
    }
    if (Object.keys(query).length > 0) {
        console.debug('query', query)
    }


    useEffect(() => {
        handleFetchRecords();
    }, [page, limit, keyword, sortColumn, direction]);


    let selectedRecords = undefined;
    if (allowedActions.includes(rbacActions.DeletePolicy)) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }

    const sortingInitialState = {
        columnAccessor: sortColumn ? sortColumn : 'createdDate',
        direction: direction ? direction : 'desc',
    };
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<NEXTPolicy>>(sortingInitialState);

    const handleTableSort = (sortStatus: DataTableSortStatus<NEXTPolicy>) => {
        console.debug('handleSort', sortStatus);
        handleSort(sortStatus.columnAccessor, sortStatus.direction);
        setSortStatus(sortStatus);
    }


    return (
        <Stack>

            <PacTableToolbar tools={[
                <Box style={{minWidth: '400px'}}>
                    <PacTableToolbarKeywordFilter
                        key={'keyword-filter'}
                        title={'Filter by description'}
                        keyword={keyword}
                        handleFilterByKeyword={handleFilterByKeyword}
                    />
                </Box>,
                <PacTableToolbarPageSizeSelector
                    key='page-size-selector'
                    limit={limit}
                    handleItemsPerPageChange={handleItemsPerPageChange}/>,
            ]}/>


            <DataTable
                minHeight={200}
                noRecordsText="No records found"
                verticalSpacing={'sm'}
                withTableBorder
                borderRadius={'md'}
                shadow={'sm'}
                striped
                columns={columns}
                records={visibleItems as any}
                totalRecords={parseInt(totalCount)}
                recordsPerPage={parseInt(limit)}
                page={page}
                onPageChange={handlePageChange}
                paginationText={({
                                     from,
                                     to,
                                     totalRecords
                                 }) => <Box my={'sm'}>Records {from} - {to} of {totalRecords}</Box>}
                paginationSize={'md'}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={(items) => {
                    handleClearSelection();
                    items.forEach(item => {
                        handleRowSelection(item)
                    })
                }}
                sortStatus={sortStatus}
                onSortStatusChange={handleTableSort}
                fetching={loading}
            />

        </Stack>
    );
};
