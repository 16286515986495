import {
    getPolicyStore,
    listPolicyStores
} from './queries';

import {
    createPolicyStore,
    deletePolicyStore,
    importPolicyStore,
    updatePolicyStore
} from './mutations';


import {
    CreatePolicyStore,
    DeletePolicyStore,
    GetPolicyStore,
    ImportPolicyStore,
    ListPolicyStores,
    PolicyStore,
    UpdatePolicyStore
} from "../types.ts";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {IListRecordsQuery} from "../../../types";

const client = generateClient();

export const fetchRecords = async (query: IListRecordsQuery) => {

    const {
        limit,
        nextToken,
        ...rest
    } = query;

    try {
        const response = await client.graphql<GraphQLQuery<ListPolicyStores>>({
                                                                                  query: listPolicyStores,
                                                                                  variables: {
                                                                                      filter: {
                                                                                          ...rest
                                                                                      },
                                                                                      limit: limit,
                                                                                      nextToken: nextToken,
                                                                                  }
                                                                              })

        return response.data?.listPolicyStores;
    } catch (e) {
        console.debug(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPolicyStore>>({
                                                                                query: getPolicyStore,
                                                                                variables: {
                                                                                    id: id
                                                                                },
                                                                            });
        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }
    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: PolicyStore) => {

    console.debug('IN ADD POLICY STORE ', payload)

    const variables = {
        input: {
            ...payload
        }
    };

    try {

        const response = await client.graphql<GraphQLQuery<CreatePolicyStore>>({
                                                                                   query: createPolicyStore,
                                                                                   variables: variables,
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store created');
            return response.data.createPolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const importRecord = async (payload: Omit<PolicyStore, 'validationSettings'>) => {

    console.debug('IN IMPORT POLICY STORE ', payload)

    const variables = {
        input: {
            ...payload
        }
    };

    try {
        const response = await client.graphql<GraphQLQuery<ImportPolicyStore>>({
                                                                                   query: importPolicyStore,
                                                                                   variables: variables,
                                                                               });

        console.debug('IMPORT POLICY STORE RESPONSE', response)
        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store created');
            return response.data.importPolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (id: string, record: PolicyStore) => {

    console.debug('IN UPDATE POLICY STORE RECORD', id, record)

    const variables = {
        input: {
            id: id,
            name: record.name,
            description: record.description,
            deploymentEnvironmentId: record.deploymentEnvironmentId,
            identityProviderId: record.identityProviderId,
            validationSettings: record.validationSettings
        }
    };

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePolicyStore>>(
            {
                query: updatePolicyStore,
                variables: variables,
            });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store updated');
            return response.data.updatePolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePolicyStore>>({
                                                                                   query: deletePolicyStore,
                                                                                   variables: {
                                                                                       input: {
                                                                                           id: id
                                                                                       }
                                                                                   },
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store deleted');
            return response.data.deletePolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};
