import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    importRecord,
    update
} from '../../policy-stores/services/PolicyStoresService';

import {
    deleteOneCase,
    fetchManyWithTokenPaginationCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {PolicyStore} from "../types.ts";
import {moduleConfig} from "../config";
import {IListRecordsQuery} from "../../../types";
import {visibilitySelector} from "../selectors.ts";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: PolicyStore) => {
    return Object.assign({}, record, {id: record.id});
};

// genenrates pending, fulfilled and rejected
export const fetchPolicyStores = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: { [k: string]: any }, {getState}) => {

        const state = getState()

        if (typeof query === 'undefined') {
            query = {} as { [key: string]: string };
        }

        const {
            limit,
            nextToken,
            keyword
        } = visibilitySelector(state)


        let listQuery = {
            limit: limit,
            nextToken: nextToken,
            ...query
        } as IListRecordsQuery;

        if (keyword) {
            listQuery = {
                keyword,
                ...listQuery,
            };
        }


        return fetchRecords(listQuery)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return {
                        items: response.items.map((record: PolicyStore) => normalizeRecord(record)),
                        nextToken: response.nextToken,
                    };
                }
            })
    },
);

export const fetchPolicyStore = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });


export const savePolicyStore = createAsyncThunk(
    `${entityKey}/add`,
    (payload: PolicyStore) => {
        return add(payload)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const importPolicyStore = createAsyncThunk(
    `${entityKey}/import`,
    (payload: PolicyStore) => {
        return importRecord(payload)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const updatePolicyStore = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: PolicyStore }) => {

        return update(id, record)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deletePolicyStore = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const policyStoresSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyWithTokenPaginationCase(builder, fetchPolicyStores, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchPolicyStore);

                                              // SAVE ONE
                                              saveOneCase(builder, savePolicyStore);
                                              saveOneCase(builder, importPolicyStore);

                                              // UPDATE ONE
                                              updateOneCase(builder, updatePolicyStore);

                                              // DELETE ONE
                                              deleteOneCase(builder, deletePolicyStore);
                                          },
                                      });

export default policyStoresSlice.reducer;
