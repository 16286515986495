import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Fieldset,
    Stack,
    Text
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup,
    PacFormikSwitch,
    PacFormikTextarea
} from '@pac/platform-ui-components';
import {PolicyStoreFormValues} from "../types.ts";
import {DeploymentEnvironmentsAutocomplete} from "../../deployment-environments/inputs/DeploymentEnvironmentsAutocomplete.tsx";
import {IdentityProvidersAutocomplete} from "../../identity-providers/inputs/IdentityProvidersAutocomplete.tsx";


export const CreateNewFormComponent = (props: FormikProps<PolicyStoreFormValues>) => {

    const {
        values,
    } = props;

    return (
        <Stack gap={'xl'}>


            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Policy Store Details</Text>}>
                <Field
                    name='name'
                    label={I18n.get('Name')}
                    description={I18n.get('Policy Store Name')}
                    component={PacFormikInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='description'
                    label={I18n.get('Description')}
                    description={I18n.get('User friendly description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />

            </Fieldset>

            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Policy Store Schema</Text>}>

                <Field
                    name='validationSettings.mode'
                    label={I18n.get('Validation Settings Mode')}
                    description={I18n.get('Selecting Strict requires generation of entities schema')}
                    component={PacFormikRadioGroup}
                    options={[{
                        label: 'OFF',
                        value: 'OFF'
                    }, {
                        label: 'STRICT',
                        value: 'STRICT'
                    }]}
                    withAsterisk
                />
            </Fieldset>


            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Deployment Environment</Text>}>
                <Field
                    name="useDefaultDeploymentEnvironment"
                    label={'Use Default Deployment Environment - pac-shared-svcs-dev/test/prd [ us-west-2 ]'}
                    value={values.useDefaultDeploymentEnvironment}
                    component={PacFormikSwitch}
                    withAsterisk
                    onLabel={'Yes'}
                    offLabel={'No'}
                    my={'xl'}
                />


            </Fieldset>


            {!values.useDefaultDeploymentEnvironment ?
                <Fieldset legend={<Text fw={800} tt={'uppercase'}>Deployment Environment</Text>}>

                    <Field
                        name="deploymentEnvironmentId"
                        label={'Deployment Environment'}
                        description={I18n.get('Account and region the policy store will be created')}
                        component={DeploymentEnvironmentsAutocomplete}
                        withAsterisk
                        my={'xl'}
                    />


                </Fieldset> : null}


            {values.useDefaultDeploymentEnvironment ?
                <Fieldset legend={<Text fw={800} tt={'uppercase'}>Identity Provider</Text>}>

                    <Field
                        name="identityProviderId"
                        label={'Identity Provider'}
                        description={I18n.get('Select Identity Provider if applicable')}
                        component={IdentityProvidersAutocomplete}
                        my={'xl'}
                    />

                </Fieldset> : null}


        </Stack>
    );
};
