import {DeploymentEnvironment} from "../types.ts";
import {
    renderBreadcrumbs,
    rootPathElements
} from "./CommonPageNavigation.tsx";


export const EditPageNavigation = ({record}: { record: DeploymentEnvironment }) => {


    const elements = rootPathElements()
        .concat([
                    {title: `${record.platformAccount?.name} [ ${record.region} ]`}
                ]);
    return renderBreadcrumbs(elements)
};
