import {
    useEffect,
    useState
} from 'react';

import {
    Box,
    Stack
} from '@mantine/core';

import {usePolicyStores} from '../hooks/usePolicyStores';

import {
    PacTableToolbar,
    PacTableToolbarKeywordFilter,
    PacTableToolbarPageSizeSelector,
} from '@pac/platform-ui-components';
import {PolicyStore} from "../types.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {usePolicyStoresPaging} from "../hooks/usePolicyStoresPaging.ts";
import {usePolicyStoresSearch} from "../hooks/usePolicyStoresSearch.ts";
import {usePolicyStoresSelection} from "../hooks/usePolicyStoresSelection.ts";
import {useRecordsTableColumns} from "../hooks/useRecordsTableColumns.tsx";
import {usePolicyStoresSorting} from '../hooks/usePolicyStoresSorting.ts';
import {
    DataTable,
    DataTableSortStatus
} from "mantine-datatable";


export const RecordsTable = () => {


    const {allowedActions} = useVerifiedPermissions()

    const {
        visibleItems,
        loading,
        handleFetchRecords,
    } = usePolicyStores();

    const {
        limit,
        page,
        totalCount,
        handleItemsPerPageChange,
        handlePageChange,
    } = usePolicyStoresPaging();

    const {
        keyword,
        handleFilterByKeyword,
    } = usePolicyStoresSearch();

    const {
        column: sortColumn,
        direction,
        handleSort,
    } = usePolicyStoresSorting();


    const {
        selection,
        handleRowSelection,
        handleClearSelection
    } = usePolicyStoresSelection();

    const {columns} = useRecordsTableColumns()

    useEffect(() => {
        handleFetchRecords();
    }, []);


    let selectedRecords = undefined;
    if (allowedActions.includes(rbacActions.DeletePolicyStore)) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }

    const sortingInitialState = {
        columnAccessor: sortColumn ? sortColumn : 'timestamp',
        direction: direction ? direction : 'desc',
    };
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<PolicyStore>>(sortingInitialState);

    const handleTableSort = (sortStatus: DataTableSortStatus<PolicyStore>) => {
        console.debug('handleSort', sortStatus);
        handleSort(sortStatus.columnAccessor, sortStatus.direction);
        setSortStatus(sortStatus);
    }

    return (
        <Stack>
            <PacTableToolbar tools={[
                <Box style={{minWidth: '400px'}}>
                    <PacTableToolbarKeywordFilter
                        key={'keyword-filter'}
                        title={'Filter by application name'}
                        keyword={keyword}
                        handleFilterByKeyword={handleFilterByKeyword}
                    />
                </Box>,
                <PacTableToolbarPageSizeSelector key='page-size-selector' limit={limit}
                                                 handleItemsPerPageChange={handleItemsPerPageChange}/>,
            ]}/>


            <DataTable
                minHeight={200}
                noRecordsText="No records found"
                verticalSpacing={'sm'}
                withTableBorder
                borderRadius={'md'}
                shadow={'sm'}
                striped
                columns={columns}
                records={visibleItems as any}
                totalRecords={parseInt(totalCount)}
                recordsPerPage={parseInt(limit)}
                page={page}
                onPageChange={handlePageChange}
                paginationText={({
                                     from,
                                     to,
                                     totalRecords
                                 }) => <Box my={'sm'}>Records {from} - {to} of {totalRecords}</Box>}
                paginationSize={'md'}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={(items) => {
                    handleClearSelection();
                    items.forEach(item => {
                        handleRowSelection(item)
                    })
                }}
                sortStatus={sortStatus}
                onSortStatusChange={handleTableSort}
                fetching={loading}
            />

        </Stack>
    );


};
