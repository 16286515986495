import {I18n} from '@aws-amplify/core';

import {
    PacFormikInput,
    PlatformFieldset,
    TableBody,
    TableDataItem,
    TableHeaderColumn,
    TableHeaderRow
} from '@pac/platform-ui-components';


import {Field} from 'formik';

import {
    Stack,
    Table
} from '@mantine/core';

import {FormValuesToActionsPayload} from './BulkActionsValueHelper';

import pluralize from 'pluralize';

import _ from 'lodash'
import {BulkFormLinkedInput} from "./BulkFormLinkedInput.tsx";
import {EntityTypeNamesMultiSelect} from "../../../entity-types/inputs/EntityTypeNamesMultiSelect.tsx";


export const BulkFormComponent = ({values}: {
    values: {
        resourceNameSingular: string,
        resourceNamePlural: string,
        appliesTo: {
            resourceTypes: string[],
            principalTypes: string[],
        }
    }
}) => {
    const columns = [
        {
            id: 'name',
            title: I18n.get('Action Name'),
            width: '1fr',
            dataKey: 'name',
        },
        {
            id: 'appliesTo.principalTypes',
            title: I18n.get('Principal Types'),
            width: '1fr',
            dataKey: 'appliesTo.principalTypes',
            render: (item: TableDataItem) => {
                return item.appliesTo.principalTypes.join(', ');
            }
        },
        {
            id: 'appliesTo.resourceTypes',
            title: I18n.get('Resource Types'),
            width: '1fr',
            dataKey: 'appliesTo.resourceTypes',
            render: (item: TableDataItem) => {
                return item.appliesTo.resourceTypes.join(', ');
            }
        },
    ];

    const actionPayloads = FormValuesToActionsPayload(values);

    let actions: object[] = [];

    if (values.resourceNameSingular) {
        actions = [
            actionPayloads.list,
            actionPayloads.create,
            actionPayloads.get,
            actionPayloads.update,
            actionPayloads.delete,
        ];
    }


    const pluralizeInputValue = (value: string) =>
        _.upperFirst(_.camelCase(pluralize(value)
                                     .toLowerCase()));


    const linkedInputFields: { name: string, cb: (value: string) => string }[] = [
        {
            name: 'resourceNamePlural',
            cb: pluralizeInputValue
        },
    ];


    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend='Actions Detail'>
                <Field
                    type="text"
                    name="resourceNameSingular"
                    description="type the resource for which we define access control. i.e. customer"
                    label="Resource Name - Singular"
                    linkedInputFields={linkedInputFields}
                    component={BulkFormLinkedInput}
                    my={'xl'}
                    withAsterisk
                />

                <Field
                    type="text"
                    name="resourceNamePlural"
                    label="Resource Name - Plural"
                    component={PacFormikInput}
                    my={'xl'}
                    withAsterisk
                />
            </PlatformFieldset>

            <PlatformFieldset legend='Actions Apply To'>

                <Field
                    name='appliesTo.resourceTypes'
                    label={I18n.get('Resource Types')}
                    description={I18n.get('Resource Types allowed')}
                    my={'xl'}
                    component={EntityTypeNamesMultiSelect}
                />

                <Field
                    name='appliesTo.principalTypes'
                    label={I18n.get('Principal Types')}
                    description={I18n.get('Principal Types allowed')}
                    my={'xl'}
                    component={EntityTypeNamesMultiSelect}
                />


            </PlatformFieldset>

            <PlatformFieldset legend='Generated Actions'>

                <Table verticalSpacing="sm" striped highlightOnHover>
                    <TableHeaderRow
                        columns={columns as TableHeaderColumn[]}
                    />
                    <TableBody items={actions as TableDataItem[]} selection={{}} loading={false} columns={columns}/>
                </Table>

                <Field type="hidden" name="generatedActions" value={actions}/>

            </PlatformFieldset>
        </Stack>
    );
};

