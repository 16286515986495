import {
    AiOutlineApartment,
    AiOutlineControl,
    AiOutlineDatabase,
    AiOutlineGroup,
    AiOutlineHome
} from "react-icons/ai";
import {PlatformNavbarMenuItem} from "@pac/platform-ui-components";
import {rbacActions} from "../../../config/rbac/actions.ts";


export const getMenuItems = () => {


    const items: PlatformNavbarMenuItem[] = [
        {
            id: 'home',
            link: '/dashboard',
            label: 'Home',
            icon: <AiOutlineHome/>,
            rbac: rbacActions.GetDashboard,
        },
        {
            id: 'policy-stores',
            link: '/policy-stores',
            label: 'Policy Stores',
            icon: <AiOutlineGroup/>,
            rbac: rbacActions.ListPolicyStores,
        },
        {
            id: 'identity-providers',
            link: '/identity-providers',
            label: 'Identity Providers',
            icon: <AiOutlineControl/>,
            rbac: rbacActions.ListIdentityProviders,
        },
        {
            id: 'deployment-environments',
            link: '/deployment-environments',
            label: 'Deployment Environments',
            icon: <AiOutlineApartment/>,
            rbac: rbacActions.ListDeploymentEnvironments,
        },
        {
            id: 'data-sources',
            link: '/data-sources',
            label: 'Data Sources',
            icon: <AiOutlineDatabase/>,
            rbac: rbacActions.ListDataSources,
        },
    ];

    return items;
};

