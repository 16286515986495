import {
    Field,
    FormikProps
} from 'formik';

import {
    Fieldset,
    Stack,
    Text
} from '@mantine/core';
import {PolicyStoreFormValues} from "../types.ts";
import {DeploymentEnvironmentsAutocomplete} from "../../deployment-environments/inputs/DeploymentEnvironmentsAutocomplete.tsx";
import {AvpPolicyStoresDropdown} from "../../avp-policy-stores/inputs/AvpPolicyStoresDropdown.tsx";


export const ImportExistingFormComponent = (props: FormikProps<PolicyStoreFormValues>) => {

    const {values} = props;

    return (
        <Stack gap={'xl'}>

            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Existing Policy Store Details</Text>}>

                <Field
                    name="deploymentEnvironmentId"
                    label={'Deployment Environment'}
                    component={DeploymentEnvironmentsAutocomplete}
                    my={'xl'}
                />

                {/*<Field*/}
                {/*    name="existingPolicyStoreId"*/}
                {/*    label={'Policy Store ID'}*/}
                {/*    component={PacFormikInput}*/}
                {/*    my={'xl'}*/}
                {/*/>*/}

                {values.deploymentEnvironmentId ? <Field
                    name="existingPolicyStoreId"
                    label={'Policy Store ID'}
                    component={AvpPolicyStoresDropdown}
                    my={'xl'}
                /> : null}

            </Fieldset>


        </Stack>
    );
};
