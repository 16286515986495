import {I18n} from 'aws-amplify/utils';

import {Group} from "@mantine/core";
import {AddRowButtonProps} from "./types";
import {NextAddButton} from "@pac/platform-ui-components";


export const AddRowButton = ({
                                 row,
                                 push,
                             }: AddRowButtonProps) => {


    return (
        <Group>
            <NextAddButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    push(row);
                }}
            >
                {I18n.get("Add")}
            </NextAddButton>
        </Group>
    );
};


