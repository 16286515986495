export const listActions = `
query listActions($policyStoreId: ID!) {
  listActions(policyStoreId: $policyStoreId) {
    items {
      name
      policyStoreId
      memberOf {
        id
      }
      appliesTo {
        resourceTypes
        principalTypes
      }
    }
  }
}
`

export const getAction = `
  query getAction($id: ID!) {
    getAction(id: $id){
      name
      policyStoreId
      memberOf {
        id
      }
      appliesTo {
        resourceTypes
        principalTypes
      }
    }
  }  
`;