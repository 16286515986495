import {
    Field,
    FormikProps
} from 'formik';

import {
    Fieldset,
    Stack,
    Text
} from '@mantine/core';

import {PacFormikRadioCardGroup} from '@pac/platform-ui-components';
import {PolicyStoreFormValues} from "../types.ts";
import {CreateNewFormComponent} from "./CreateNewFormComponent.tsx";
import {ImportExistingFormComponent} from "./ImportExistingFormComponent.tsx";


export const AddFormComponent = (props: FormikProps<PolicyStoreFormValues>) => {

    const {
        values,
        // setFieldValue
    } = props;


    return (
        <Stack gap={'xl'}>

            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Policy Store</Text>}>
                <Field
                    name="policyStoreAction"
                    value={values.policyStoreAction}
                    onChange={(value: string) => {
                        console.debug('on change value', value)
                        // setFieldValue('policyStoreId', '')
                        // setFieldValue('deploymentEnvironmentId', '')
                    }}
                    component={PacFormikRadioCardGroup}
                    data={[
                        {
                            value: 'create',
                            label: 'Create new policy store',
                            description: 'Create a new policy store in the selected deployment environment'
                        },
                        {
                            value: 'import',
                            label: 'Use existing policy store',
                            description: 'Connect an existing policy store. Requires delegation IAM policy, if not in default deployment env.'
                        }
                    ]}
                    my={'xl'}
                />


            </Fieldset>


            {values.policyStoreAction && values.policyStoreAction.toLowerCase() === 'create' ?
                <CreateNewFormComponent {...props} /> : null}

            {values.policyStoreAction && values.policyStoreAction.toLowerCase() === 'import' ?
                <ImportExistingFormComponent {...props} /> : null}


        </Stack>
    );
};
