import {createSlice} from '@reduxjs/toolkit';

const DEFAULT_SORT_COLUMN = 'created';
const DEFAULT_SORT_DIRECTION = 'desc';


export const SortingSliceFor = (entityKey: string, initialState = {
    column: DEFAULT_SORT_COLUMN,
    direction: DEFAULT_SORT_DIRECTION,
}) => {


    const slice = createSlice({
                                  name: entityKey,
                                  initialState,
                                  reducers: {
                                      setSortingColumn: (state, action) => {
                                          state.column = action.payload;
                                      },
                                      setSortingDirection: (state, action) => {
                                          state.direction = action.payload;
                                      },
                                      setSorting: (state, action) => {
                                          state.column = action.payload.column;
                                          state.direction = action.payload.direction;
                                      },
                                      resetSorting: (state) => {
                                          state.column = DEFAULT_SORT_COLUMN;
                                          state.direction = DEFAULT_SORT_DIRECTION;
                                      },
                                  },
                              });

    return slice;
};
