import {
    bulkDeleteActions,
    bulkSaveActions,
    fetchActions
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTAction} from "../types.ts";

export const useActions = (policyStoreId: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
    } = useAppSelector((state) => visibilitySelector(state, policyStoreId));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchActions(policyStoreId));
    };

    // CREATE, UPDATE DELETE
    const handleDeleteRecords = () => {

        const actions = [] as NEXTAction[];
        
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                actions.push(selection[key]);
            }
        }

        dispatch(bulkDeleteActions(
            {
                policyStoreId,
                actions: actions
            }));

    };


    const handleBulkCreateRecords = (actions: NEXTAction[]) => {

        dispatch(bulkSaveActions({
                                     policyStoreId,
                                     actions
                                 }));
    };


    return {
        items,
        visibleItems,
        loading,
        handleFetchRecords,
        handleDeleteRecords,
        handleBulkCreateRecords,
    };
};
