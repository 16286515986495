import {I18n} from '@aws-amplify/core';

import {SimplePageHeader} from '@pac/platform-ui-components';
import {ActionIcon} from "../images/ActionIcon.tsx";
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {EditRecordButton} from "../buttons/EditRecordButton.tsx";
import {ReloadRecordsButton} from "../buttons/ReloadRecordsButton.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ReloadRecordsButton key="reload"/>];

    if (allowedActions.includes(rbacActions.UpdateAction)) {
        tools.push(<EditRecordButton key="edit-action"/>);
    }

    if (allowedActions.includes(rbacActions.DeleteAction)) {
        tools.push(<DeleteRecordButton key="delete"/>);
    }

    if (allowedActions.includes(rbacActions.CreateAction)) {
        tools.push(<AddRecordButton key="add"/>);
    }

    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Actions')}
            icon={<ActionIcon/>}
            tools={useTools()}
        />
    );
};
