import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    PacFormikInput,
    PacFormikRadioGroup,
    PacFormikTextarea,
    PlatformFieldset
} from '@pac/platform-ui-components';
import {PolicyTemplate} from "../types.ts";
import {ActionNamesMultiSelect} from "../../actions/inputs/ActionNamesMultiSelect.tsx";
import {Stack} from "@mantine/core";


const inputMethodOptions = [
    {
        label: 'Permit Action',
        value: 'permit',
    },
    {
        label: 'Forbid Action',
        value: 'forbid',
    },
];

export const FormComponent = ({values}: { values: PolicyTemplate }) => {

    const editing =
        typeof values !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(values, 'policyId') &&
        typeof values.policyTemplateId !== 'undefined' &&
        values.policyTemplateId !== '' && values.policyTemplateId !== null
            ? true
            : false;


    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend={'Policy Template Detail'}>

                <Field
                    name="policyAction"
                    label="Policy Action"
                    options={inputMethodOptions}
                    component={PacFormikRadioGroup}
                    withAsterisk
                    my={'xl'}
                />


                <Field
                    name='description'
                    label={I18n.get('Policy Description')}
                    placeholder={I18n.get('Policy Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />

                <Field
                    name='actions'
                    label='Actions'
                    description='Select policy template actions'
                    placeholder={I18n.get('Actions')}
                    component={ActionNamesMultiSelect}
                />

            </PlatformFieldset>


            {editing ? <PlatformFieldset legend={'Metadata'}>

                {editing ? <Field
                    name='policyId'
                    label={I18n.get('Policy ID')}
                    description={I18n.get('Policy Id')}
                    component={PacFormikInput}
                    disabled={true}
                    withAsterisk
                /> : null}

                {editing ? <Field
                    name='policyStoreId'
                    label={I18n.get('Policy Store ID')}
                    description={I18n.get('Policy Store ID')}
                    component={PacFormikInput}
                    disabled={true}
                    withAsterisk
                /> : null}


            </PlatformFieldset> : null}


        </Stack>
    );


};
