import {BreadcrumbElement,} from "../../../types";
import {usePolicyStore} from "../../policy-stores/hooks/usePolicyStore.ts";
import {useEffect} from "react";


export const useBreadcrumbMenuItems = (policyStoreId: string): BreadcrumbElement[] => {
    const {
        record: policyStore,
        handleFetchRecord
    } = usePolicyStore(policyStoreId);

    useEffect(() => {
        handleFetchRecord();
    }, []);

    if (typeof policyStore === 'undefined') {
        return [];
    }

    return [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: 'Policy Stores',
            href: `/policy-stores`
        },
        {
            title: policyStore.name,
            href: `/policy-stores/${policyStore.id}/permissions`
        }
    ];

}

