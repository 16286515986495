import {Badge} from "@mantine/core";
import {
    IdentityProvider,
    IdentityProviderStatus
} from "../types.ts";

export const ProviderStatusBadge = ({record}: { record: IdentityProvider }) => {
    const options = record.status === IdentityProviderStatus.ACTIVE ?
        {
            label: 'Active',
            color: 'blue'
        } : {
            label: 'Inactive',
            color: 'gray'
        }

    return <Badge color={options.color}>{options.label}</Badge>
}