import {NextReloadButton} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom';

import {useEntityTypes} from '../hooks/useEntityTypes';

export const ReloadRecordsButton = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {handleFetchRecords} = useEntityTypes(policyStoreId);
    return (<NextReloadButton onClick={handleFetchRecords}/>);
};

