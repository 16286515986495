import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    bulkDelete,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../entity-types/services/EntityTypesService';

import {
    deleteManyCase,
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTEntityType} from "../types.ts";
import {moduleConfig} from "../config";
import getUuid from "uuid-by-string";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};


const normalizeRecord = (record: NEXTEntityType) => {
    return Object.assign({}, record, {id: getUuid(record.name)});
};


// genenrates pending, fulfilled and rejected
export const fetchEntityTypes = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (policyStoreId: string) => {

        return fetchRecords(policyStoreId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchEntityType = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as NEXTEntityType);
            });
    });


export const saveEntityType = createAsyncThunk(
    `${entityKey}/add`,
    (payload: NEXTEntityType) => {
        return add(payload)
            .then((response) => normalizeRecord(response as NEXTEntityType));
    });

export const updateEntityType = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: {
        id: string, record: NEXTEntityType
    }) => {

        return update(id, record)
            .then((response) => {
                return normalizeRecord(response as NEXTEntityType);
            });
    },
);

export const deleteEntityType = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         policyStoreId,
         name
     }: { policyStoreId: string, name: string }) => {
        return deleteRecord(policyStoreId, name)
            .then(() => getUuid(name));
    },
);

export const bulkDeleteEntityTypes = createAsyncThunk(
    `${entityKey}/bulkDelete`,
    ({
         policyStoreId,
         entityTypes
     }: { policyStoreId: string, entityTypes: NEXTEntityType[] }) => {
        return bulkDelete(policyStoreId, entityTypes)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return response.map((record) => {
                        return getUuid(record.name)
                    });
                }
            });
    },
);

const entityTypesSlice = createSlice({
                                         name: entityKey,
                                         initialState,
                                         reducers: {},
                                         extraReducers: (builder) => {
                                             // FETCH MANY
                                             fetchManyCase(builder, fetchEntityTypes, entityKey);

                                             // FETCH ONE
                                             fetchOneCase(builder, fetchEntityType);

                                             // SAVE ONE
                                             saveOneCase(builder, saveEntityType);

                                             // UPDATE ONE
                                             updateOneCase(builder, updateEntityType);

                                             // DELETE ONE
                                             deleteOneCase(builder, deleteEntityType);

                                             // DELETE MANY
                                             deleteManyCase(builder, bulkDeleteEntityTypes)
                                         },
                                     });

export default entityTypesSlice.reducer;
