import {
    FunctionComponent,
    useEffect
} from 'react';

import {useParams} from 'react-router-dom';

import {PlatformLoader} from '@pac/platform-ui-components';

import {usePolicyStoreSchema} from '../hooks/usePolicyStoreSchema';
import {useDocumentTitle} from "@mantine/hooks";
import {EditPage} from "./EditPage.tsx";
import {AddPage} from "./AddPage.tsx";
import {Stack} from "@mantine/core";

export const IndexPage: FunctionComponent = () => {

    const {
        id: policyStoreId,
    } = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record: policyStoreSchema,
        handleFetchRecord,
    } = usePolicyStoreSchema(policyStoreId);

    useEffect(() => {
        handleFetchRecord();
    }, []);

    useDocumentTitle(`Policy Store Schema`);

    if (!policyStoreSchema) {
        return <PlatformLoader message='Loading policy store schema...Please wait'/>;
    }


    return (
        <Stack>
            {policyStoreSchema.schema !== '{}' ? <EditPage/> : <AddPage/>}
        </Stack>
    );
};
