export const createPolicyStoreSchema = `
  mutation createPolicyStoreSchema($input: CreatePolicyStoreSchemaInput!) {
    createPolicyStoreSchema(input: $input){
      schema
      policyStoreId
    }
  }  
`;

export const updatePolicyStoreSchema = `
  mutation updatePolicyStoreSchema($input: UpdatePolicyStoreSchemaInput!) {
    updatePolicyStoreSchema(input: $input){
      schema
      policyStoreId
    }
  }
`;

export const deletePolicyStoreSchema = `
  mutation deletePolicyStoreSchema($input: DeletePolicyStoreSchemaInput!) {
    deletePolicyStoreSchema(input: $input){
      policyStoreId
    }
  }  
`;
