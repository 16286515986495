import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        userPools: { name: string, arn: string }[],
    }
}

const config: ConfigShape = {
    prd: {
        userPools: [{
            name: 'PanaSky User Pool',
            arn: 'arn:aws:cognito-idp:us-west-2:827098250628:userpool/us-west-2_1f0VHRTZ9'
        }],
    },
    test: {
        userPools: [{
            name: 'PanaSky User Pool',
            arn: 'arn:aws:cognito-idp:us-west-2:766605398863:userpool/us-west-2_Jcz7Vrpul'
        }],
    },
    dev: {
        userPools: [{
            name: 'PanaSky User Pool',
            arn: 'arn:aws:cognito-idp:us-west-2:364161771924:userpool/us-west-2_xr1nstZBn',
        }],
    },
    ide: {
        userPools: [{
            name: 'PanaSky User Pool',
            arn: 'arn:aws:cognito-idp:us-west-2:364161771924:userpool/us-west-2_xr1nstZBn',
        }, {
            name: 'Emirates User Pool',
            arn: 'arn:aws:cognito-idp:us-west-2:364161771924:userpool/us-west-2_jDbCq2M50',
        }],
    },
};

export default config[stage];
