import {IdentitySource} from "../types.ts";
import {usePolicyStoreBreadcrumbElements} from "../../policy-stores/hooks/usePolicyStoreBreadcrumbElements.tsx";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";

export const EditPageNavigation = ({record}: { record: IdentitySource }) => {

    const {elements: rootPathElements} = usePolicyStoreBreadcrumbElements(record.permissionSetId)

    const elements = rootPathElements.concat([
                                                 {title: 'IdentitySources'},
                                                 {title: record.identitySourceId}
                                             ]);
    return renderBreadcrumbs(elements)
};
