export const createEntityType = `
  mutation createEntityType($input: CreateEntityTypeInput!) {
    createEntityType(input: $input){
      name
      policyStoreId
      memberOfTypes
    }
  }  
`;

export const updateEntityType = `
  mutation updateEntityType($input: UpdateEntityTypeInput!) {
    updateEntityType(input: $input){
      name
      policyStoreId
      memberOfTypes
    }
  }
`;

export const deleteEntityType = `
  mutation deleteEntityType($input: DeleteEntityTypeInput!) {
    deleteEntityType(input: $input){
      name
    }
  }  
`;

export const bulkDeleteEntityTypes = `
  mutation bulkDeleteEntityTypes($input: BulkDeleteEntityTypesInput!) {
    bulkDeleteEntityTypes(input: $input){
        items {
            name
        }
    }
  }  
`;
