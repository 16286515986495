import {Cache} from 'aws-amplify/utils';

import config from '../config';

const METADATA_LOCAL_STORAGE_KEY = '-pac-app-metadata';
const METADATA_LOCAL_STORAGE_VALUE = {
    version: 1,
};

const LATEST_APP_VERSION = config.about.latestVersion;

const metadata = Cache.getItem(METADATA_LOCAL_STORAGE_KEY, {
    callback: () => {
        return METADATA_LOCAL_STORAGE_VALUE;
    },
});

metadata.then(version => {
    if (!version || (version && version.version < LATEST_APP_VERSION)) {
        // clear
        Cache.clear();
        // store
        Cache.setItem(METADATA_LOCAL_STORAGE_KEY, {
            version: LATEST_APP_VERSION
        });
    }
})



