import {
    useEffect,
    useState
} from 'react';

import {
    Autocomplete,
    AutocompleteProps,
    Group,
    Text
} from '@mantine/core';
import {useIdentityProviders} from "../hooks/useIdentityProviders";
import {FieldProps} from "formik";
import {IdentityProvider} from "../types.ts";


export const IdentityProvidersAutocomplete = ({
                                                  field,
                                                  form: {setFieldValue},
                                                  ...rest
                                              }: FieldProps) => {

    const [value, setValue] = useState<string>(field.value);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        items: records,
        handleFetchRecords
    } = useIdentityProviders()


    useEffect(() => {
        handleFetchRecords();
    }, []);

    const renderAutocompleteOption: AutocompleteProps['renderOption'] = ({option}) => {
        const record = records.find((record: IdentityProvider) => record.name === option.value);
        return (
            <Group gap="sm">
                <div>
                    <Text size="sm">{record.name}</Text>
                </div>
            </Group>
        )
    };

    const data = records.map((record: IdentityProvider) => {
        return record.name
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        setValue(value);

        const found = records.find((record: IdentityProvider) => record.name === value);

        if (!found) {
            throw new Error('Identity Provider not found');
        }

        setFieldValue(field.name, found.id);

    };

    return (
        <Autocomplete
            name={field.name}
            value={value}
            onChange={handleChange}
            renderOption={renderAutocompleteOption}
            data={data}
            disabled={disabled}
            placeholder='Select Identity Provider'
            {...rest}
        />
    );
};
