import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {setSorting} from "../reducers";
import {sortSelector} from "../selectors/sort.ts";

export const usePoliciesSorting = () => {

    const dispatch = useAppDispatch();


    const {
        column,
        direction,
    } = useAppSelector((state) => sortSelector(state));


    const handleSort = (column: string, direction: string) => {
        dispatch(setSorting({
                                column,
                                direction
                            }));
    };

    return {
        column,
        direction,
        handleSort,
    };
};
