import {
    setPage,
    setPageSize,
} from '../reducers/index';

import {visibilitySelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";

export const usePolicyStoresPaging = () => {
    const dispatch = useAppDispatch();

    const {
        limit,
        page,
        totalPages,
        totalCount
    } =
        useAppSelector((state) => visibilitySelector(state));


    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };


    return {
        limit,
        page,
        totalPages,
        totalCount,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
