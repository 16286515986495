export const createPolicyStore = `
  mutation createPolicyStore($input: CreatePolicyStoreInput!) {
    createPolicyStore(input: $input){
      id
      name
      description
      validationSettings {
        mode
      }
      arn
      deploymentEnvironmentId
      deploymentEnvironment {
        platformAccount {
          name
        }
        region
      }
      identityProviderId
      identityProvider {
        name
      }
      policyStoreType
      created
      createdByUsername
      updated
    }
  }  
`;

export const importPolicyStore = `
  mutation importPolicyStore($input: ImportPolicyStoreInput!) {
    importPolicyStore(input: $input){
      id
      name
      description
      validationSettings {
        mode
      }
      arn
      deploymentEnvironmentId
      deploymentEnvironment {
        platformAccount {
          name
        }
        region
      }
      identityProviderId
      identityProvider {
        name
      }
      policyStoreType
      created
      createdByUsername
      updated
    }
  }  
`;

export const updatePolicyStore = `
  mutation updatePolicyStore($input: UpdatePolicyStoreInput!) {
    updatePolicyStore(input: $input){
      id
      name
      description
      validationSettings {
        mode
      }
      arn
      deploymentEnvironmentId
      deploymentEnvironment {
        platformAccount {
          name
        }
        region
      }
      identityProviderId
      identityProvider {
        name
      }
      policyStoreType
      created
      createdByUsername
      updated
      managedByUsername
    }
  }
`;

export const deletePolicyStore = `
  mutation deletePolicyStore($input: DeletePolicyStoreInput!) {
    deletePolicyStore(input: $input){
      id
    }
  }  
`;
