import {FormikProps} from 'formik';
import {PolicyStoreFormValues} from "../types.ts";
import {EditFormComponent} from './EditFormComponent.tsx';
import {AddFormComponent} from './AddFormComponent.tsx';


export const FormComponent = (props: FormikProps<PolicyStoreFormValues>) => {

    const {
        values,
    } = props;

    const editing =
        typeof values !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(values, 'id') &&
        typeof values.id !== 'undefined' &&
        values.id !== '' && values.id !== null
            ? true
            : false;

    return editing ? <EditFormComponent {...props}/> : <AddFormComponent {...props}/>;
    
};
