import {
    deletePermissionGroup,
    fetchPermissionGroups
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector
} from '../selectors';

import {columns} from '../tables/columns';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";


export const usePermissionGroups = (policyStoreId: string) => {
    const dispatch = useAppDispatch();


    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        visibleCount
    } =
        useAppSelector((state) => visibilitySelector(state, policyStoreId));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );

    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchPermissionGroups({
                                           policyStoreId: policyStoreId
                                       }));
    };


    // MULTIPLE DELETE
    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deletePermissionGroup({
                                                   policyStoreId,
                                                   id: key
                                               }));
            }
        }
    };


    return {
        items,
        visibleItems,
        loading,
        visibleCount,
        columns,
        handleFetchRecords,
        handleDeleteRecords,
    };
};
