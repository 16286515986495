import {DataTableColumn} from "mantine-datatable";
import {
    NavLink,
    useNavigate
} from "react-router-dom";
import {I18n} from 'aws-amplify/utils';
import {NEXTPolicy} from "../types.ts";
import {
    NextButton,
    NextCloseButton
} from "@pac/platform-ui-components";
import {
    AiOutlineEye,
    AiOutlineProfile
} from "react-icons/ai";
import {modals} from "@mantine/modals";
import {
    Box,
    Group
} from "@mantine/core";
import {PolicyPreviewPage} from "../pages/PolicyPreviewPage.tsx";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";

const PreviewComponent = ({item}: { item: NEXTPolicy }) => {

    const navigate = useNavigate()
    return (
        <NextButton
            variant="outline"
            leftSection={<AiOutlineEye/>}
            onClick={() => {
                modals.open({
                                title: 'Preview Policy',
                                size: 'xl',
                                children: (
                                    <Box>
                                        <PolicyPreviewPage policyStoreId={item.policyStoreId}
                                                           policyId={item.id}/>
                                        <Group justify={'flex-end'}>
                                            <NextCloseButton onClick={() => modals.closeAll()} mt="md"/>
                                            <NextButton
                                                leftSection={<AiOutlineProfile/>}
                                                onClick={() => {
                                                    modals.closeAll()
                                                    navigate(`/policy-stores/${item.policyStoreId}/policies/${item.id}`)
                                                }
                                                }
                                                mt="md">Open</NextButton>
                                        </Group>
                                    </Box>
                                ),
                            });
            }}
        >
            Preview
        </NextButton>
    )

}

export const useRecordsTableColumns = () => {


    const columns: DataTableColumn<NEXTPolicy>[] = [

        {
            accessor: 'id',
            title: I18n.get('Policy ID'),
            render: (item: NEXTPolicy) => {
                return (
                    <NavLink
                        to={{
                            pathname: `/policy-stores/${item.policyStoreId}/permissions/policies/${item.id}`,
                        }}
                    >
                        {item.policyId}
                    </NavLink>
                );
            },
        },
        {
            accessor: 'policyId',
            title: I18n.get('Preview'),
            width: '1fr',
            render: (item: NEXTPolicy) => <PreviewComponent item={item}/>
        },
        {
            accessor: 'definition.static.description',
            title: I18n.get('Description'),
            width: '1fr',
            render: (item: NEXTPolicy) => item.definition && item.definition.static ? item.definition.static.description : 'n/a'
        },
        {
            accessor: 'createdDate',
            title: I18n.get('Created Date'),
            sortable: true,
            render: (item: NEXTPolicy) => formatTimestamp(item.createdDate),
        },

    ];

    return {
        columns: columns,
    };
}