import {NEXTAction} from "../../types.ts";

export const FormValuesToActionsPayload = (values: {
    resourceNameSingular: string,
    resourceNamePlural: string,
    appliesTo: {
        resourceTypes: string[],
        principalTypes: string[],
    }
}) => {
    // const nameSingular = values.resourceNameSingular;
    // const namePlural = values.resourceNamePlural;

    const listName = ['List', values.resourceNamePlural].join('');
    const createName = ['Create', values.resourceNameSingular].join('');
    const getName = ['Get', values.resourceNameSingular].join('');
    const updateName = ['Update', values.resourceNameSingular].join('');
    const deleteName = ['Delete', values.resourceNameSingular].join('');


    const listPayload = {
        id: 'list-name',
        name: listName,
        appliesTo: values.appliesTo
    } as NEXTAction;
    const createPayload = {
        id: 'create-name',
        name: createName,
        appliesTo: values.appliesTo
    } as NEXTAction;
    const getPayload = {
        id: 'get-name',
        name: getName,
        appliesTo: values.appliesTo
    } as NEXTAction;
    const updatePayload = {
        id: 'update-name',
        name: updateName,
        appliesTo: values.appliesTo
    } as NEXTAction;
    const deletePayload = {
        id: 'delete-name',
        name: deleteName,
        appliesTo: values.appliesTo
    } as NEXTAction;


    return {
        list: listPayload,
        create: createPayload,
        get: getPayload,
        update: updatePayload,
        delete: deletePayload,
    };
};
