import {I18n} from '@aws-amplify/core';

import {NavLink} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {
    IdentityProvider,
    IIdentityProviderTableColumn
} from "../types.ts";
import {ProviderStatusBadge} from '../profile/ProviderStatusBadge.tsx';


export const columns: IIdentityProviderTableColumn[] = [
    {
        title: I18n.get('Identity Provider Name'),
        render: (item: IdentityProvider) => {
            return (
                <NavLink
                    to={{
                        pathname: `/identity-providers/${item.id}`,
                    }}
                >
                    {item.name}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: IdentityProvider) => <ProviderStatusBadge record={item}/>,
    },
    {
        title: I18n.get('Created By'),
        width: '1fr',
        render: (item: IdentityProvider) =>
            ((typeof item.createdBy !== 'undefined' && item.createdBy !== null)
                ? [item.createdBy.givenName, item.createdBy.familyName].join(
                    ' ',
                )
                : item.createdByUsername),
    },
    {
        title: I18n.get('Managed By'),
        width: '1fr',
        render: (item: IdentityProvider) =>
            ((typeof item.managedBy !== 'undefined' && item.managedBy !== null)
                ? [item.managedBy.givenName, item.managedBy.familyName].join(
                    ' ',
                )
                : item.managedByUsername),
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: IdentityProvider) => formatTimestamp(item.created),
    },
];
