import {
    getIdentityProvider,
    listIdentityProviders
} from './queries';

import {
    createIdentityProvider,
    deleteIdentityProvider,
    updateIdentityProvider
} from './mutations';

import {
    CreateIdentityProvider,
    DeleteIdentityProvider,
    GetIdentityProvider,
    IdentityProvider,
    ListIdentityProviders,
    UpdateIdentityProvider
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();

export const fetchRecords = async (query: { [k: string]: any }) => {

    let filter = {}
    if (query.filter) {
        filter = query.filter
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListIdentityProviders>>({
                                                                                       query: listIdentityProviders,
                                                                                       variables: {
                                                                                           filter: filter,
                                                                                       }
                                                                                   });

        return response.data?.listIdentityProviders.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetIdentityProvider>>({
                                                                                     query: getIdentityProvider,
                                                                                     variables: {
                                                                                         id: id
                                                                                     },
                                                                                 });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getIdentityProvider;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: IdentityProvider) => {

    console.debug('FORM PAYLOAD', payload)


    const variables = Object.assign({}, {
        input: {
            name: payload.name,
            providerType: payload.providerType,
            description: payload.description,
            userPoolArn: payload.userPoolArn,
            clientIds: payload.clientIds,
            status: payload.status
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreateIdentityProvider>>({
                                                                                        query: createIdentityProvider,
                                                                                        variables: variables,
                                                                                    });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Identity Provider created');
            return response.data.createIdentityProvider;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (id: string, record: IdentityProvider) => {

    console.debug('IN UPDATE ENTITY TYPE SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: record.id,
            name: record.name,
            providerType: record.providerType,
            description: record.description,
            userPoolArn: record.userPoolArn,
            clientIds: record.clientIds,
            status: record.status
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateIdentityProvider>>({
                                                                                        query: updateIdentityProvider,
                                                                                        variables: variables,
                                                                                    });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Identity Provider updated');
            return response.data.updateIdentityProvider;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteIdentityProvider>>({
                                                                                        query: deleteIdentityProvider,
                                                                                        variables: {
                                                                                            input: {
                                                                                                id: id
                                                                                            }
                                                                                        },
                                                                                    });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Identity Provider deleted');
            return response.data.deleteIdentityProvider;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
