export const listAvpPolicyStores = `
query listAvpPolicyStores($deploymentEnvironmentId: String, $limit: Int, $nextToken: String) {
  listAvpPolicyStores(deploymentEnvironmentId: $deploymentEnvironmentId, limit: $limit, nextToken: $nextToken) {
    items {
      policyStoreId
      description
    }
  }
}
`


export const getAvpPolicyStore = `
  query getAvpPolicyStore($deploymentEnvironmentId: String, $id: ID!) {
    getAvpPolicyStore(deploymentEnvironmentId: $deploymentEnvironmentId, id: $id){
      policyStoreId
      description
    }
  }  
`;