export const moduleConfig = {
    entityKey: 'tenants'
}


export const tenantTypeOptions = [
    {
        label: 'Production',
        value: 'prod'
    },
    {
        label: 'Test',
        value: 'test'
    },
    {
        label: 'Development',
        value: 'dev'
    },
];

export const tenantCategoryOptions = [
    {
        label: 'Airline',
        value: 'airline'
    },
    {
        label: 'Airline Group',
        value: 'airline group'
    },
    {
        label: 'Content Distributor',
        value: 'content distributor'
    },
    {
        label: 'Contractor',
        value: 'contractor'
    },
    {
        label: 'Non Civilian',
        value: 'non civilian'
    },
    {
        label: 'Partner',
        value: 'partner'
    },
    {
        label: 'Provider',
        value: 'provider'
    },
    {
        label: 'Vendor',
        value: 'vendor'
    },
];
