import {
    deletePermissionGroup,
    fetchPermissionGroup,
    savePermissionGroup,
    updatePermissionGroup
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {PermissionGroup} from "../types.ts";

export const usePermissionGroup = (policyStoreId: string, permissionGroupId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, permissionGroupId ? permissionGroupId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof permissionGroupId === 'undefined') {
            throw new Error('permissionGroupId is required parameter')
        }

        dispatch(fetchPermissionGroup({
                                          policyStoreId: policyStoreId,
                                          id: permissionGroupId
                                      }));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: PermissionGroup) => {
        dispatch(savePermissionGroup({
                                         policyStoreId: policyStoreId,
                                         payload: values
                                     }));
    };

    const handleUpdateRecord = (values: PermissionGroup) => {

        if (typeof permissionGroupId === 'undefined') {
            throw new Error('permissionGroupId is required parameter')
        }

        dispatch(updatePermissionGroup({
                                           policyStoreId: policyStoreId,
                                           id: permissionGroupId,
                                           record: values
                                       }));
    };

    const handleDeleteRecord = () => {

        if (typeof permissionGroupId === 'undefined') {
            throw new Error('permissionGroupId is required parameter')
        }

        dispatch(deletePermissionGroup({
                                           policyStoreId: policyStoreId,
                                           id: permissionGroupId
                                       }));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
