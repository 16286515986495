import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/AddPageNavigation.tsx";
import {usePolicyStore} from "../hooks/usePolicyStore";
import {useDocumentTitle} from "@mantine/hooks";

export const AddPage = () => {

    const {
        record,
        handleCreateRecord,
        handleImportRecord
    } = usePolicyStore();

    useDocumentTitle('Add Policy Store');

    return (
        <FormPageLayout
            title='Add Policy Store'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record}
                            handleSubmit={handleCreateRecord}
                            handleImport={handleImportRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
