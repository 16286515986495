import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {stage} from '../../../config/stage';
import {IdentitySource,} from "../types.ts";
import {IdentityProviderType} from "../../identity-providers/types.ts";


export const RecordForm = ({
                               record,
                               handleSubmit
                           }: {
    record: IdentitySource,
    handleSubmit: (values: IdentitySource) => void
}) => {
    const navigate = useNavigate();

    const {id: permissionSetId} = useParams();

    const onSubmit = (values: IdentitySource) => {
        console.debug('onSubmit', values);
        handleSubmit(values);
        navigate(`/permission-sets/${permissionSetId}/#schema`);
    };

    const onCancel = () => {
        navigate(`/permission-sets/${permissionSetId}/#schema`);
    };


    let initialValues = {
        providerType: IdentityProviderType.AMAZON_COGNITO,
        principalEntityType: 'CognitoUser',
        groupEntityType: 'CognitoGroup'
    } as IdentitySource;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent handleSubmit={handleSubmit} isSubmitting={isSubmitting} {...rest}/>
                </FormWithButtons>)}
        </Formik>
    );
};

