import {RecordsTable} from '../tables/RecordsTable';

import {
    SimplePageContentWrapper,
    SimplePageLayout
} from '@pac/platform-ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {IndexPageNavigation} from '../navigation/IndexPageNavigation';
import {useDocumentTitle} from "@mantine/hooks";

export const IndexPage = () => {

    useDocumentTitle('Policy Stores')

    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
            pageNavigation={<IndexPageNavigation/>}
        >
            <SimplePageContentWrapper><RecordsTable/></SimplePageContentWrapper>
        </SimplePageLayout>
    );
};
