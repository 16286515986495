import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {usePolicyTemplate} from "../hooks/usePolicyTemplate";
import {useParams} from 'react-router-dom'
import {AddPageNavigation} from '../navigation/AddPageNavigation';
import {useDocumentTitle} from "@mantine/hooks";

export const AddPage = () => {
    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        handleCreateRecord
    } = usePolicyTemplate(policyStoreId);

    useDocumentTitle('Add Policy Template');

    return (
        <FormPageLayout
            title='Add Policy Template'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm
                    record={record}
                    handleSubmit={handleCreateRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
