import {useEffect} from 'react';

import {
    SimpleGrid,
    Table
} from '@mantine/core';

import {useEntityTypes} from '../hooks/useEntityTypes';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@pac/platform-ui-components';
import {useParams} from "react-router-dom";
import {
    IEntityTypeTableColumn,
    NEXTEntityType
} from "../types.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {useEntityTypesSelection} from "../hooks/useEntityTypesSelection.ts";
import {useEntityTypesSearch} from "../hooks/useEntityTypesSearch.ts";
import {useEntityTypesPaging} from "../hooks/useEntityTypesPaging.ts";

type RecordsTableProps = {
    extraTools?: JSX.Element[],
    settings?: { label: string, onClick: () => void }[]
}

export const RecordsTable = ({
                                 extraTools,
                                 settings
                             }: RecordsTableProps) => {

    const {id: policyStoreId} = useParams();


    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const {allowedActions} = useVerifiedPermissions()

    const {
        columns,
        visibleItems,
        loading,
        handleFetchRecords,
    } = useEntityTypes(policyStoreId);

    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useEntityTypesSelection(policyStoreId);

    const {
        handleFilterByKeyword,
    } = useEntityTypesSearch(policyStoreId);

    const {
        limit,
        totalPages,
        visibleCount,
        handleItemsPerPageChange,
        handlePageChange,
    } = useEntityTypesPaging(policyStoreId);

    useEffect(() => {
        handleFetchRecords();
    }, []);

    const renderRowSelectionDataCell = (item: NEXTEntityType) => (
        <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
    );

    const getColumns = () => {
        const cols: IEntityTypeTableColumn[] = [];
        if (allowedActions.includes(rbacActions.DeleteEntityType)) {
            cols.push({
                          id: 'select',
                          title: '',
                          render: renderRowSelectionDataCell,
                      },
            );
        }
        return cols.concat(columns);
    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                totalCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
