import {Outlet} from 'react-router-dom';

import {
    NextButton,
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderToolbar
} from '@pac/platform-ui-components';

import {
    AppShell,
    Box,
    Group,
    Stack,
    Title,
} from '@mantine/core';
import {signInWithRedirect} from "aws-amplify/auth";
import classes from "./GuestLayout.module.css";

export const GuestLayout = () => {

    let header = <PlatformHeader/>;

    const SignInButton = () => {
        return (
            <NextButton onClick={() => {
                signInWithRedirect()
            }} className={classes.signInButton}>
                <Title order={6}>Sign In</Title>
            </NextButton>
        )
    }

    const toolbar = <PlatformHeaderToolbar
        tools={
            [
                <SignInButton/>
            ]
        }/>;

    header = <PlatformHeader toolbar={toolbar}/>;

    return (
        <AppShell
            header={{height: 70}}
            padding="md"
        >
            <AppShell.Header>
                {header}
            </AppShell.Header>

            <AppShell.Main>
                <Box className={classes.main}>
                    <Stack gap={'xl'}>
                        <Title order={1}>Welcome to PAC Cloud Permissions!</Title>
                        <Title order={3}>Please sign in to continue.</Title>
                        <Group mt={'xl'} justify={'center'}>
                            <SignInButton/>
                        </Group>
                    </Stack>
                    <Outlet/>
                </Box>
            </AppShell.Main>

            <AppShell.Footer p={'md'}>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    )


};
