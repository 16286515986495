import {
    Group,
    Text
} from '@mantine/core';
import {NEXTAction} from "../types.ts";
import {
    PlatformPropertiesCard,
    PlatformPropertiesCardProperty
} from "@pac/platform-ui-components";


const EntitiesCollection = ({entities}: { entities: string[] }) => {
    return (
        <Group ml={'1em'} gap={'xs'}>
            {entities && entities.map(entity => {
                return (<Text key={entity}>{entity}</Text>)
            })}
        </Group>
    );
};

export const Profile = ({record}: { record: NEXTAction }) => {

    const properties = [
        {
            label: 'Action Name',
            value: record.name,
        },
        {
            label: 'Action Group',
            value: record.memberOf ? record.memberOf.map(member => member.id)
                                           .join(', ') : [],
        },
        {
            label: 'Resource Types',
            value: (
                <EntitiesCollection
                    entities={record.appliesTo?.resourceTypes ? record.appliesTo?.resourceTypes : []}/>),
        },
        {
            label: 'Principal Types',
            value: (<EntitiesCollection
                entities={record.appliesTo?.principalTypes ? record.appliesTo?.principalTypes : []}/>),
        }] as PlatformPropertiesCardProperty[];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );

};