import {NEXTPolicyStoreSchema} from "../types.ts";
import {JsonInput} from "@mantine/core";


export const Profile = ({record}: { record: NEXTPolicyStoreSchema }) => {


    return (
        <JsonInput
            value={JSON.stringify(JSON.parse(record.schema), null, 2)}
            readOnly
            autosize
            minRows={10}/>
    );


};

