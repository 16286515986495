export const listIdentitySources = `
query listIdentitySources($filter: IdentitySourcesFilterInput!, $limit: Int, $nextToken: String) {
  listIdentitySources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      identitySourceId
      policyStoreId
      providerType
      userPoolArn
      clientIds
      groupEntityType
      principalEntityType
      created
      updated
    }
  }
}
`

export const getIdentitySource = `
  query getIdentitySource($deploymentEnvironmentId: ID!, $policyStoreId: ID!, $identitySourceId: ID!) {
    getIdentitySource(deploymentEnvironmentId: $deploymentEnvironmentId, policyStoreId: $policyStoreId, identitySourceId: $identitySourceId){
      identitySourceId
      policyStoreId
      providerType
      userPoolArn
      clientIds
      groupEntityType
      principalEntityType
      created
      updated
    }
  }  
`;