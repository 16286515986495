import {
    setPage,
    setPageSize,
} from '../reducers/index';

import {visibilitySelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";


export const useEntityTypesPaging = (policyStoreId: string) => {

    const dispatch = useAppDispatch();


    const {
        limit,
        page,
        totalPages,
        visibleCount
    } =
        useAppSelector((state) => visibilitySelector(state, policyStoreId));


    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };


    return {
        limit,
        page,
        totalPages,
        visibleCount,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
