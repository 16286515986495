import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    update
} from '../services/PolicyStoreSchemasService';

import {
    deleteOneCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTPolicyStoreSchema} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};


const normalizeRecord = (record: NEXTPolicyStoreSchema) => {
    return Object.assign({}, record, {id: record.policyStoreId});
};


export const fetchPolicyStoreSchema = createAsyncThunk(
    `${entityKey}/fetch`,
    (policyStoreId: string) => {
        return fetch(policyStoreId)
            .then((response) => {
                return normalizeRecord(response as NEXTPolicyStoreSchema);
            });
    });


export const savePolicyStoreSchema = createAsyncThunk(
    `${entityKey}/add`,
    (payload: NEXTPolicyStoreSchema) => {
        return add(payload)
            .then((response) => normalizeRecord(response as NEXTPolicyStoreSchema));
    });

export const updatePolicyStoreSchema = createAsyncThunk(
    `${entityKey}/update`,
    (record: NEXTPolicyStoreSchema) => {

        return update(record)
            .then((response) => {
                return normalizeRecord(response as NEXTPolicyStoreSchema);
            });
    },
);

export const deletePolicyStoreSchema = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (policyStoreId: string) => {
        return deleteRecord(policyStoreId)
            .then(() => policyStoreId);
    },
);

const policyStoreSchemasSlice = createSlice({
                                                name: entityKey,
                                                initialState,
                                                reducers: {},
                                                extraReducers: (builder) => {

                                                    // FETCH ONE
                                                    fetchOneCase(builder, fetchPolicyStoreSchema);

                                                    // SAVE ONE
                                                    saveOneCase(builder, savePolicyStoreSchema);

                                                    // UPDATE ONE
                                                    updateOneCase(builder, updatePolicyStoreSchema);

                                                    // DELETE ONE
                                                    deleteOneCase(builder, deletePolicyStoreSchema);
                                                },
                                            });

export default policyStoreSchemasSlice.reducer;
