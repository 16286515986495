import {AppLayout} from "../layout/AppLayout.tsx";
import {PublicHomePage} from "../features/home/pages/PublicHomePage.tsx";
import {DashboardLayout} from "../features/dashboard/layout.tsx";
import {PolicyStoresLayout} from "../features/policy-stores/layout.tsx";
import {SignOutPage} from "../features/home/pages/SignOutPage.tsx";
import {ForbiddenPage} from "../features/home/pages/403-Forbidden.tsx";
import {PageNotFoundPage} from "../features/home/pages/404-NotFound.tsx";
import {RequireAuth} from "../utils/RequireAuth.tsx";
import {IdentityProvidersLayout} from "../features/identity-providers/layout.tsx";
import {DeploymentEnvironmentsLayout} from "../features/deployment-environments/layout.tsx";
import {DataSourcesLayout} from "../features/data-sources/layout.tsx";


export const routesConfig = [{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>,
    }, {
        path: 'dashboard/*',
        element: <RequireAuth><DashboardLayout/></RequireAuth>,
    }, {
        path: 'policy-stores/*',
        element: <RequireAuth><PolicyStoresLayout/></RequireAuth>,
    }, {
        path: 'identity-providers/*',
        element: <RequireAuth><IdentityProvidersLayout/></RequireAuth>,
    }, {
        path: 'deployment-environments/*',
        element: <RequireAuth><DeploymentEnvironmentsLayout/></RequireAuth>,
    }, {
        path: 'data-sources/*',
        element: <RequireAuth><DataSourcesLayout/></RequireAuth>,
    }, {
        path: 'signout',
        element: <SignOutPage/>,
    }, {
        path: '403',
        element: <ForbiddenPage/>,
    }, {
        path: '*',
        element: <PageNotFoundPage/>,
    }],
}]