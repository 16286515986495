import {PolicyTemplate} from "../types.ts";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";
import {useBreadcrumbMenuItems} from "../hooks/useBreadcrumbMenuItems.tsx";


export const EditPageNavigation = ({record}: { record: PolicyTemplate }) => {

    const elements = useBreadcrumbMenuItems(record.policyStoreId)
        .concat([
                    {
                        title: 'Policy Templates'
                    },
                    {title: record.policyTemplateId}
                ]);
    return renderBreadcrumbs(elements)
};
