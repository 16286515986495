import {
    deletePolicyTemplate,
    fetchPolicyTemplate,
    savePolicyTemplate,
    updatePolicyTemplate
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {PolicyTemplateFormValues} from "../types.ts";

export const usePolicyTemplate = (policyStoreId: string, policyTemplateId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, policyTemplateId ? policyTemplateId : ''),
    );

    // FETCH
    const handleFetchRecord = () => {

        if (typeof policyTemplateId === 'undefined') {
            throw new Error('policyTemplateId is required parameter')
        }

        dispatch(fetchPolicyTemplate({
                                         policyStoreId: policyStoreId,
                                         id: policyTemplateId
                                     }));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: PolicyTemplateFormValues) => {
        dispatch(savePolicyTemplate({
                                        policyStoreId: policyStoreId,
                                        payload: values
                                    }));
    };

    const handleUpdateRecord = (values: PolicyTemplateFormValues) => {

        if (typeof policyTemplateId === 'undefined') {
            throw new Error('policyTemplateId is required parameter')
        }

        dispatch(updatePolicyTemplate({
                                          policyStoreId: policyStoreId,
                                          id: policyTemplateId,
                                          record: values
                                      }));
    };

    const handleDeleteRecord = () => {

        if (typeof policyTemplateId === 'undefined') {
            throw new Error('policyTemplateId is required parameter')
        }

        dispatch(deletePolicyTemplate({
                                          policyStoreId: policyStoreId,
                                          id: policyTemplateId
                                      }));
    };

    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
