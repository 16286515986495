import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    PacFormikInput,
    PlatformFieldset
} from '@pac/platform-ui-components';
import {Stack} from '@mantine/core';
import {EntityTypeNamesMultiSelect} from "../../../entity-types/inputs/EntityTypeNamesMultiSelect.tsx";
import {NEXTAction} from "../../types.ts";
import {PacFormikList} from "../../../../components/PacFormikList";
import {ActionGroupsAutocomplete} from "../../inputs/ActionGroupsAutocomplete.tsx";

export const FormComponent = (_: FormikProps<NEXTAction>) => {


    const rowElements = () => ([
        {
            name: 'id',
            placeholder: 'Group',
            component: ActionGroupsAutocomplete,
            span: 12,
        },
    ]);


    return (
        <Stack gap={'xl'}>
            <PlatformFieldset legend='Actions Detail'>
                <Field
                    name='name'
                    label={I18n.get('Action Name')}
                    description={I18n.get('Use double colon to prepend action namespace i.e. Portal::CreateApplication')}
                    component={PacFormikInput}
                    my={'xl'}
                    withAsterisk={true}
                />

            </PlatformFieldset>

            <PlatformFieldset legend='Action Group'>
                <Field
                    name='memberOf'
                    description='Action groups themselves are actions. You can reference action groups in your policies to avoid enumerating actions there manually'
                    component={PacFormikList}
                    getRowElements={() => {
                        return rowElements();
                    }}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend='Applies To'>

                <Field
                    name='appliesTo.resourceTypes'
                    label={I18n.get('Resource Types')}
                    description={I18n.get('Resource Types allowed')}
                    my={'xl'}
                    component={EntityTypeNamesMultiSelect}
                />

                <Field
                    name='appliesTo.principalTypes'
                    label={I18n.get('Principal Types')}
                    description={I18n.get('Principal Types allowed')}
                    my={'xl'}
                    component={EntityTypeNamesMultiSelect}
                />


            </PlatformFieldset>

        </Stack>
    );


};

