import {
    getAction,
    listActions
} from './queries';

import {
    bulkCreateActions,
    bulkDeleteActions,
    createAction,
    deleteAction,
    updateAction
} from './mutations';


import {
    generateClient,
    GraphQLQuery
} from '@aws-amplify/api';
import {
    BulkCreateActions,
    BulkDeleteActions,
    CreateAction,
    DeleteAction,
    GetAction,
    ListActions,
    NEXTAction,
    UpdateAction
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";

const client = generateClient();

export const fetchRecords = async (policyStoreId: string) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListActions>>(
            {
                query: listActions,
                variables: {
                    policyStoreId: policyStoreId
                }
            });

        return response.data?.listActions.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (policyStoreId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetAction>>({
                                                                           query: getAction,
                                                                           variables: {
                                                                               policyStoreId: policyStoreId,
                                                                               name: id
                                                                           },
                                                                       });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: NEXTAction) => {

    const variables = Object.assign({}, {
        input: {
            policyStoreId: payload.policyStoreId,
            name: payload.name,
            memberOf: payload.memberOf,
            appliesTo: payload.appliesTo,
        }
    });


    try {
        const response = await client.graphql<GraphQLQuery<CreateAction>>({
                                                                              query: createAction,
                                                                              variables: variables,
                                                                          });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Action created');
            return response.data.createAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e)
    }
};

export const update = async (id: string, record: NEXTAction) => {

    console.debug('update', id, record);

    const variables = Object.assign({}, {
        input: {
            policyStoreId: record.policyStoreId,
            name: record.name,
            memberOf: record.memberOf,
            appliesTo: record.appliesTo,
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateAction>>({
                                                                              query: updateAction,
                                                                              variables: variables,
                                                                          });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Action updated');
            return response.data.updateAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (policyStoreId: string, name: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteAction>>({
                                                                              query: deleteAction,
                                                                              variables: {
                                                                                  input: {
                                                                                      policyStoreId: policyStoreId,
                                                                                      name: name
                                                                                  }
                                                                              },
                                                                          });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Action deleted');
            return response.data.deleteAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};


export const bulkAdd = async (policyStoreId: string, payload: NEXTAction[]) => {


    const modifiedPayload = payload.map((action: NEXTAction) => {
        return {
            name: action.name,
            memberOf: action.memberOf,
            appliesTo: action.appliesTo,
        }
    });


    try {
        const response = await client.graphql<GraphQLQuery<BulkCreateActions>>({
                                                                                   query: bulkCreateActions,
                                                                                   variables: {
                                                                                       input: {
                                                                                           policyStoreId: policyStoreId,
                                                                                           actions: modifiedPayload
                                                                                       }
                                                                                   },
                                                                               });

        console.debug('bulkAdd response', response);

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Bulk Actions created');
            return response.data.bulkCreateActions.items as NEXTAction[];
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        console.error(e);
        notifyError(e)
    }
};

export const bulkDelete = async (policyStoreId: string, payload: NEXTAction[]) => {


    const modifiedPayload = payload.map((action: NEXTAction) => {
        return {
            name: action.name,
        }
    });


    try {
        const response = await client.graphql<GraphQLQuery<BulkDeleteActions>>({
                                                                                   query: bulkDeleteActions,
                                                                                   variables: {
                                                                                       input: {
                                                                                           policyStoreId: policyStoreId,
                                                                                           actions: modifiedPayload
                                                                                       }
                                                                                   },
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Bulk actions deleted');
            return response.data.bulkDeleteActions.items as NEXTAction[];
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        console.error(e);
        notifyError(e)
    }
};
