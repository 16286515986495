import {FieldProps} from 'formik';

import {TextInput} from '@mantine/core';

import _ from 'lodash'

export const BulkFormLinkedInput = ({
                                        field: {
                                            name,
                                            value,

                                            onChange
                                        },
                                        form: {setFieldValue},
                                        linkedInputFields,
                                        ...rest
                                    }: FieldProps & {
    linkedInputFields: { name: string, cb: (value: string) => string }[]
}) => {

    const onInputBlur = () => {
        //
        const fieldValue = value.trim();
        const normalizedValue = _.upperFirst(
            _.camelCase(fieldValue.toLowerCase())
        );

        setFieldValue(name, normalizedValue);

        linkedInputFields.forEach((record) => {
            setFieldValue(record.name, record.cb(value));
        });
    };

    return <TextInput
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onInputBlur}
        {...rest}
    />

}


