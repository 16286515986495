import {I18n} from '@aws-amplify/core';

import {NextAddButton} from '@pac/platform-ui-components';

import {useNavigate} from 'react-router-dom';


export const AddRecordButton = () => {
    const navigate = useNavigate();


    return (
        <NextAddButton
            onClick={() => {
                navigate(`permission-groups/add`);
            }}
        >
            {I18n.get('Add Permission Group')}
        </NextAddButton>
    );
};

