import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {
    Box,
    Center,
    SegmentedControl
} from "@mantine/core";
import {PoliciesPageSegment} from "../page-segments/PoliciesPageSegment.tsx";
import {SchemaPageSegment} from "../page-segments/SchemaPageSegment.tsx";

import {PolicyIcon} from "../../policies/images/PolicyIcon.tsx";
import {MdOutlineSchema} from "react-icons/md";
import {AiOutlineGroup} from "react-icons/ai";
import {GroupsPageSegment} from "../page-segments/GroupsPageSegment.tsx";


export const EditPageSegmentsNavigationControl = () => {

    const navigate = useNavigate()
    const {
        id: policyStoreId,
        segment,
    } = useParams();

    let pageSegment = 'permissions';
    if (typeof segment !== 'undefined') {
        pageSegment = segment;
    }


    const segmentedControlData = [
        {
            label: <Center><PolicyIcon/><Box ml={'sm'}>Policies</Box></Center>,
            value: 'permissions'
        },
        {
            label: <Center><MdOutlineSchema/><Box ml={'sm'}>Schema</Box></Center>,
            value: 'schema'
        },
        {
            label: <Center><AiOutlineGroup/><Box ml={'sm'}>Groups</Box></Center>,
            value: 'groups'
        },
    ];


    return (
        <>
            <SegmentedControl
                mb={'sm'}
                value={pageSegment}
                onChange={(value) => {
                    navigate(`/policy-stores/${policyStoreId}/${value}`)
                }}
                data={segmentedControlData}
            />

            {/*{['policies', 'policy-templates'].includes(segmentedControlValue) ?*/}
            {/*    <PoliciesPageSegment/> : null}*/}
            {/*{['schema', 'actions', 'entity-types', 'permission-groups'].includes(segmentedControlValue) ?*/}
            {/*    <SchemaPageSegment/> : null}*/}

            {pageSegment === 'permissions' ?
                <PoliciesPageSegment/> : null}
            {pageSegment === 'schema' ?
                <SchemaPageSegment/> : null}
            {pageSegment === 'groups' ?
                <GroupsPageSegment/> : null}

        </>

    );
};
