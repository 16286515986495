import {I18n} from '@aws-amplify/core';

import {NavLink} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {
    IEntityTypeTableColumn,
    NEXTEntityType
} from "../types.ts";


export const columns: IEntityTypeTableColumn[] = [
    {
        title: I18n.get('Entity Type Name'),
        render: (item: NEXTEntityType) => {
            return (
                <NavLink
                    to={{
                        pathname: `/policy-stores/${item.policyStoreId}/schema/entity-types/${item.id}`,
                    }}
                >
                    {item.name}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Membership'),
        width: '1fr',
        render: (item: NEXTEntityType) => {
            return item.memberOfTypes ? item.memberOfTypes.length : 0
        },
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: NEXTEntityType) => formatTimestamp(item.created),
    },
];
