import {FunctionComponent} from 'react';

import {
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {IndexPage as PoliciesIndex} from "../../policies/pages/IndexPage";
import {IndexPage as PolicyTemplatesIndex} from "../../policy-templates/pages/IndexPage";
import {useLocation} from "react-router-dom";

export const PoliciesPageSegment: FunctionComponent = () => {
    const {hash} = useLocation()

    const tabs = [
        {
            title: 'Policies',
            component: <PoliciesIndex/>,
            key: 'policies'
        },
        {
            title: 'Policy Templates',
            component: <PolicyTemplatesIndex/>,
            key: 'policy-templates'
        },
    ] as (PlatformTabComponentTab & { key: string })[]

    const activeTab = tabs.find((tab) => tab.key === hash.substring(1)) || tabs[0]
    activeTab.active = true

    return (
        <SimplePageContentWrapper>
            <PlatformTabbedComponent tabs={tabs}></PlatformTabbedComponent>
        </SimplePageContentWrapper>
    );
};
