import {
    useEffect,
    useState
} from 'react';

import {
    Autocomplete,
    Box
} from '@mantine/core';
import {FieldProps} from "formik";
import {useParams} from "react-router-dom";
import {useActions} from "../hooks/useActions";
import {NEXTAction} from "../types.ts";


export const ActionGroupsAutocomplete = ({
                                             field: {
                                                 name,
                                                 value: fieldValue
                                             },
                                             form: {setFieldValue},
                                             ...rest
                                         }: FieldProps) => {

    const [value, setValue] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }
    const {
        items: records,
        handleFetchRecords,
    } = useActions(policyStoreId);

    useEffect(() => {
        handleFetchRecords();
    }, []);

    const actionsData = records.reduce((acc: { [key: string]: NEXTAction }, action: NEXTAction) => {
        acc[action.name] = action;
        return acc;
    }, {}) as { [key: string]: any };

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        console.debug('records inside', records)

        const found = records.find((item: NEXTAction) => item.name === fieldValue);
        console.debug('found', found)
        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'name')) {
            setValue(found.name);
        }
    }, [records.length]);


    const renderOption = ({option}: { option: any }) => {
        return (
            <Box>
                {actionsData[option.value].name}
            </Box>
        );
    };


    const data = records.map((record: NEXTAction) => {
        return record.name
    });


    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }

        setFieldValue(name, value);
        setValue(value);
    };

    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            renderOption={renderOption}
            disabled={disabled}
            placeholder='Select Name'
            {...rest}
        />
    );
};
