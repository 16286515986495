import {Stack} from '@mantine/core';

import {PlatformLoader} from '@pac/platform-ui-components';
import {ProfilePolicyBlock} from "../profile/ProfilePolicyBlock.tsx";
import {usePolicy} from "../hooks/usePolicy.ts";
import {useEffect} from "react";

export const PolicyPreviewPage = ({
                                      policyStoreId,
                                      policyId
                                  }: { policyStoreId: string, policyId: string }) => {


    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record: policy,
        handleFetchRecord,
    } = usePolicy(policyStoreId, policyId);


    useEffect(() => {
        handleFetchRecord();
    }, []);

    if (typeof policy === 'undefined' || !policy.definition) {
        return <PlatformLoader message='Loading policy...Please wait'/>;
    }


    return (

        <Stack gap='lg'>
            <ProfilePolicyBlock
                policy={policy}
            />
        </Stack>

    );
};
