import {DataSource} from "../types.ts";
import {
    renderBreadcrumbs,
    rootPathElements
} from "./CommonPageNavigation.tsx";

export const EditPageNavigation = ({record}: { record: DataSource }) => {


    const elements = rootPathElements()
        .concat([
                    {title: record.name}
                ]);
    return renderBreadcrumbs(elements)
};
