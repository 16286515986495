import {DataTableColumn} from "mantine-datatable";
import {PolicyStore} from "../types.ts";
import {I18n} from "@aws-amplify/core";
import {NavLink} from "react-router-dom";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";


export const useRecordsTableColumns = () => {


    const columns: DataTableColumn<PolicyStore>[] = [

        {
            accessor: 'name',
            title: I18n.get('Policy Store Name'),
            render: (item: PolicyStore) => {
                return (
                    <NavLink
                        to={{
                            pathname: `/policy-stores/${item.id}/permissions`,
                        }}
                    >
                        {item.name}
                    </NavLink>
                );
            },
        },
        {
            accessor: 'id',
            title: I18n.get('ID'),
            width: '1fr',
            render: (item: PolicyStore) => item.id
        },
        {
            accessor: 'deploymentEnvironmentId',
            title: I18n.get('Deployment Environment'),
            width: '1fr',
            render: (item: PolicyStore) => item.deploymentEnvironment ? `${item.deploymentEnvironment.platformAccount?.name} [ ${item.deploymentEnvironment.region} ] ` : item.deploymentEnvironmentId
        },
        {
            accessor: 'identityProviderId',
            title: I18n.get('Identity Provider'),
            width: '1fr',
            render: (item: PolicyStore) => item.identityProvider ? item.identityProvider.name : item.identityProviderId
        },
        {
            accessor: 'policyStoreType',
            title: I18n.get('Type'),
            width: '1fr',
            render: (item: PolicyStore) => item.policyStoreType
        },
        {
            accessor: 'created',
            title: I18n.get('Created Date'),
            width: '1fr',
            render: (item: PolicyStore) => formatTimestamp(item.created),
        },


    ];

    return {
        columns: columns,
    };
}