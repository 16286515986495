import {useParams} from 'react-router-dom';
import {usePolicyStoreBreadcrumbElements} from "../../policy-stores/hooks/usePolicyStoreBreadcrumbElements.tsx";
import {renderBreadcrumbs} from "./CommonPageNavigation.tsx";


export const AddPageNavigation = () => {

    const {id: policyStoreId} = useParams();

    if (!policyStoreId) {
        throw new Error('policyStoreId is required parameter')
    }
    const {elements: rootPathElements} = usePolicyStoreBreadcrumbElements(policyStoreId)

    const elements = rootPathElements.concat([
                                                 {title: 'Add Action'}
                                             ]);
    return renderBreadcrumbs(elements)
};


